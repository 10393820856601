import { Box, Button, Radio, Tab, Tabs, Tooltip, Typography, Checkbox } from '@mui/material'
import React, { useState, useEffect, useCallback } from 'react'
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { green } from '@mui/material/colors'
import { Col, Row } from 'react-bootstrap'
import './oneWayResults.css'
import arrowimg from '../../../assets/images/arrow.svg'
import luggage from '../../../assets/images/luggage.svg'
import refund from '../../../assets/images/refund.svg'
import nonrefund from '../../../assets/images/nonrefund.svg'
import seat from '../../../assets/images/seat.svg'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import '../../../assets/airlines/airlineLogoSprites.css'
import { stopsCount } from '../../../core/airlineStops'
import { flightDate, formatDate1, formatDate, toDigit } from '../../../core/helper'
import { useDispatch, useSelector } from 'react-redux'
import { fareRulsDetails } from '../../../services/fareDetails'
// import ReactHtmlParser from 'react-html-parser'
import { API_BASE_URL } from '../../../utility/apiConstants'
import { authPost } from '../../../utility/apiConfig'
import { ssrRequest } from '../../../store/flights/actions/flightBookingSSRActions'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import arrowFlight from '../../../assets/images/arrowflight.png';

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {}
})((props) => <Radio color="default" {...props} />)

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    //   backgroundColor: '#f5f5f9',
    //   color: 'rgba(0, 0, 0, 0.87)',
    //   maxWidth: 220,
    //   fontSize: theme.typography.pxToRem(12),
    //   border: '1px solid #dadde9',
  }
}))(Tooltip)

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function viewDetailsToggle (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  arrowbox: {
    width: '90px',
    margin: '3px',
    textAlign: 'left',
    position: 'relative'
  },
  arrowimg: {
    position: 'absolute',
    top: '10px',
    width: '66px',
    left: '0px'
  },
  viewbtndetails: {
    marginTop: '0px'
  },
  flexset:
    {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '0px 0px'
    }
}))

// const images = require.context('../../../assets/airline-logo', true);

const RoundTripInternationalResults = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useNavigate()

  const { fsRequestData, fsRoundReqData, fsRoundInternationalData } = useSelector(state => state.flightResult)

    let segments = props.results.SegmentDetails[0];
  //  let segSummery = props.results.SegmentSummary[0]
  //  let airlines = segments[0].AirlineDetails;

  const [value, setValue] = useState(0)
  const [fareValues, setFareValues] = useState('0')
  const [fareDetails, setFareDetails] = useState({})
  const [fareRules, setFareRules] = useState(null)
  const [fareLoader, setFareLoader] = useState(false)

  const [paxCount, setpaxCount] = useState(fsRoundReqData.adult + fsRoundReqData.child)

  const [toggle, setToggle] = useState(false)

  const handleFlighttabsChange = (event, newValue) => {
    setValue(newValue)
// console.log("props.results", props.results)
    if (newValue === 2) {
      getFareRules(props.results)
    }
  }

  const viewDetailToggle = () => {
    setToggle(!toggle)
    setFareDetails(props.results)
    setValue(0)
  }

  const handleChangeFares = (event) => {
     setFareValues(event.target.value)
    setFareDetails(props.results)
    getFareRules(props.results)
  }

  const getFareRules = useCallback(
    (data) => {
      setFareLoader(true)
      const obj = {
        data_access_key: data.Token,
        booking_source: data.booking_source,
        search_access_key: data.ProvabAuthKey,
        provab_auth_key: 'undefined'
      }
// console.log("Obj", obj)
      try {
        authPost(API_BASE_URL + 'ajax/get_fare_details', obj)
          .then((response) => {
            setFareLoader(false)
            if (response.data.status) {
               // console.log("Response", response.data.data)
              const info = response.data.data[0]?.fare_rules
              if (info !== undefined) {
                const iic = info.concat(response.data.data[0]?.cancellation_policy)
                setFareRules(iic)
              } else {
                setFareRules(response.data.data.msg)
              }
            } else {
               setFareRules(response.data.data.msg)
            }
          }).catch((error) => {
            setFareRules("Fare details not available at this moment.")
          })
      } catch (error) {
        setFareLoader(false)
        setFareRules('Somthing went wrong please try again...')
      }
    }
  )

  const multicityBooking = () => {

    const obj = {
      is_domestic: fsRoundInternationalData.data.IsDomestic,
      token: [props.results.Token],
      token_key: [props.results.TokenKey],
      search_access_key: [props.results.ProvabAuthKey],
      promotional_plan_type: [''],
      booking_source: [fsRoundInternationalData.data.booking_source],
      alternate_flights: '',
      search_hash: fsRoundInternationalData.session_expiry_details.search_hash
    }
    dispatch(ssrRequest(obj))

    const data = {
      adult: fsRoundReqData.adult,
      child: fsRoundReqData.child,
      infant: fsRoundReqData.infant,
      isDomestic: fsRoundInternationalData.data.IsDomestic,
      domestic_round_way_flight: fsRoundInternationalData.data.domestic_round_way_flight
    }

    // history.push({
    //   pathname: '/flight/booking',
    //   state: [obj, fsRoundInternationalData.data.search_id, props.results, fsRoundInternationalData.data.cabin_class, data]
    // })

    let onward = {...props.results, "SegmentDetails": [props.results.SegmentDetails[0]], "SegmentSummary": [props.results.SegmentSummary[0]]};
    let returns = {...props.results, "SegmentDetails": [props.results.SegmentDetails[1]], "SegmentSummary": [props.results.SegmentSummary[1]]};

    //console.log("fsRoundInternationalData", props.results)

    props.setModalFlight(false);
    props.setOnewayFlight(null);
    props.setOnwardFlight(onward);
    props.setReturnFlight(returns);
    props.setIsInt(true);

  }

  useEffect(() => {
    setToggle(false)
    setFareValues('0')
  }, [props])
// console.log("props.results.SegmentDetails", props.results)
  return (

        <>
            <Box className="bgflightdetailscard">
                <Box className="viewresultbooking">
                        <>
                            <Row>
                                <Col xs={12} sm={7}>
                                    {props.results.SegmentSummary.map((segSumry, index) => (
                                    <Row>
                                        <Col xs={4} sm={4} md={4}  className="zerogutter m-auto">
                                            <Box className="flightimg">
                                                <Box className="imgminifly">
                                                    <img className={'itwgo-60x35_' + segSumry.AirlineDetails.AirlineCode} />
                                                </Box>
                                                <Box className="mini-tag">
                                                    <Box className="flightname">{segSumry.AirlineDetails.AirlineName}</Box>
                                                    <Box className="flightnumber">{segSumry.AirlineDetails.AirlineCode + '-' + segSumry.AirlineDetails.FlightNumber}</Box>
                                                </Box>
                                            </Box>
                                        </Col>
                                        <Col xs={8} sm={8} md={8} className="zerogutter">
                                            <Box className={[classes.flexset, 'flexset-intr']}>
                                                <Box className="definewidth">
                                                    <Box className="flightmininame">{segSumry.OriginDetails.AirportCode}</Box>
                                                    <Box className="flightminitime">{segSumry.OriginDetails._DateTime}</Box>
                                                    <Box className="flightminidata">{flightDate(segSumry.OriginDetails._Date)}</Box>
                                                </Box>
                                                <Box className="arrowbox">
                                                    <Box className="define-stop">{stopsCount[segSumry.TotalStops]}</Box>
                                                    <Box className="arrow-img"> <span className='arrow-line'></span><img src={arrowFlight}></img></Box>
                                                    <Box className="define-timecalc">{segSumry.TotalDuaration}</Box>
                                                </Box>
                                                <Box className="definewidth">
                                                    <Box className="flightmininame">{segSumry.DestinationDetails.AirportCode}</Box>
                                                    <Box className="flightminitime">{segSumry.DestinationDetails._DateTime}</Box>
                                                    <Box className="flightminidata">{flightDate(segSumry.DestinationDetails._Date)}</Box>
                                                </Box>
                                            </Box>
                                        </Col>
                                    </Row>
                                    ))}
                                </Col>
                                
                                <Col xs={12} sm={4} md={5} className="zerogutter align-self-center">
                                    {props.showWhatsApp && (
                                    <Box className="share-cbx ">
                                        <Checkbox 
                                            className='share-checkbox'
                                            checked={props.selectedFlight.length !== 0 ? (props.selectedFlight.indexOf(props.results.ProvabAuthKey) > -1 ? true : false) : false}
                                            onChange={()=> props.onToggleSelectedFlight(props.results.SegmentSummary[0],
                                            props.results.SegmentSummary[0].OriginDetails, 
                                            segments[props.results.SegmentSummary[0].TotalStops].DestinationDetails, 
                                            props.results.Attr, 
                                            Math.ceil(props.results.FareDetails.b2b_PriceDetails._CustomerBuying), 
                                            props.results,
                                            )}
                                        />
                                    </Box>
                                    )}       
                                    <Box className="radioBox">
                                        <Box row aria-label="position" name="position" defaultValue="top"
                                            orientation="vertical"
                                            value={fareValues}
                                            onChange={handleChangeFares}>
                                            <Row>
                                                <Col md={4} xs={4} className="servicesBoxs d-flex">
                                                    <Box className="highlight-price"><span> &#x20B9; </span>{Math.ceil(props.results.FareDetails.b2b_PriceDetails._CustomerBuying)}</Box>
                                                
                                                    {props.showNetFare && (
                                                        <Box className='pricewidth'>
                                                            <HtmlTooltip
                                                                title={
                                                                    <Box className="tooltiphovercnt">

                                                                        <p>Commision: {props.results.FareDetails.b2b_PriceDetails._Commission}</p>
                                                                        <p>Markup: {props.results.FareDetails.b2b_PriceDetails._Markup}</p>
                                                                    </Box>
                                                                }>
                                                                <Box className="shownet"><span> &#x20B9; </span> {Math.ceil(props.results.FareDetails.b2b_PriceDetails._AgentBuying)}</Box>
                                                            </HtmlTooltip>
                                                        </Box>
                                                    )}

                                                </Col>  
                                                <Col md={4} xs={4} className="servicesBoxs">   
                                                    <Box className='listiconbox'>
                                                        <ul className="listicon">
                                                            <li className="listitemsicon">
                                                                <HtmlTooltip title={
                                                                    <React.Fragment>
                                                                        Check-In Baggage {props.results.SegmentDetails[0][0].Baggage} <br />
                                                                        {!!props.results.SegmentDetails[0][0].CabinBaggage && `Cabin Baggage ${props.results.SegmentDetails[0][0].CabinBaggage}`}
                                                                    </React.Fragment>
                                                                } arrow placement="top">
                                                                    <img src={luggage}></img>
                                                                </HtmlTooltip>
                                                            </li>
                                                            {props.results.SegmentDetails[0][0].IsRefundable
                                                                ? (
                                                                <li className="listitemsicon"><Tooltip title="Refundable" arrow placement="top"><img src={refund}></img></Tooltip></li>
                                                                )
                                                                : (
                                                                <li className="listitemsicon"><Tooltip title="Non-Refundable" arrow placement="top"><img src={nonrefund}></img></Tooltip></li>
                                                                )}

                                                            <li className="listitemsicon"><Tooltip title={`Available seats ${props.results.SegmentDetails[0][0].AvailableSeats}`} arrow placement="top"><img src={seat}></img></Tooltip></li>

                                                        </ul>
                                                    </Box>
                                                </Col>
                                                <Col xs={12} sm={4} md={4} className='m-auto'>
                                                    <Button className="btnBook" variant="contained"onClick={multicityBooking}>Book</Button>                                    
                                                </Col>

                                            </Row>
                                        </Box>
                                    </Box>
                                </Col>
                               
                            </Row>
                        </>
                   


                    
                    
                </Box>
                <Row className='viewmore-box'>
                    <Col md={6} xs={6}>
                       
                    </Col>
                    <Col md={6} xs={6}>
                        <Box className="viewdetailwrp">
                        <Box className="view-more-btn" variant="contained" color="primary" onClick={viewDetailToggle}>View Details {toggle ? <KeyboardArrowDownIcon className='arrow-down-rotate' /> : <KeyboardArrowDownIcon />  }</Box>
                        </Box>
                    </Col>
                </Row>

                <Box>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="zerogap">

                            {toggle &&  (
                                <Box className="tabsBox">
                                    <Tabs className="flighttabsdetails" value={value} onChange={handleFlighttabsChange} aria-label="simple tabs example">
                                        <Tab className="flighttabsdetails-link" label="flight details" {...viewDetailsToggle(0)} />
                                        <Tab className="flighttabsdetails-link" label="fare details" {...viewDetailsToggle(1)} />
                                        <Tab className="flighttabsdetails-link" label="Fare Rules" {...viewDetailsToggle(2)} />
                                        <Tab className="flighttabsdetails-link" label="baggage info" {...viewDetailsToggle(3)} />
                                    </Tabs>
                                    <TabPanel className="tabpanelsflightpanel" value={value} index={0}>

                                        {props.results.SegmentDetails.map((segSumry, index) => (
                                            <>
                                                {segSumry.map((v, k) => (
                                                    <>
                                                        <Box className="tabcontentsbox">
                                                            <Box className="to-fromtlt">
                                                                <span className="fromname">{v.OriginDetails.CityName}</span>
                                                                <span className="icoarrowfromto"><img src={arrowimg}></img></span>
                                                                <span className="toname">{v.DestinationDetails.CityName}</span>
                                                                <span className="dateformto">{formatDate(v.OriginDetails.DateTime)}</span>
                                                            </Box>
                                                            <Row>
                                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                                    <Box className="flightimg">
                                                                        <Box className="imgminifly">
                                                                            <img className={'itwgo-60x35_' + v.AirlineDetails.AirlineCode} />
                                                                        </Box>
                                                                        <Box className="mini-tag">
                                                                            <Box className="flightname">{v.AirlineDetails.AirlineName}</Box>
                                                                            <Box className="flightnumber">{v.AirlineDetails.AirlineCode}-{v.AirlineDetails.FlightNumber}</Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Col>
                                                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                    <Row>
                                                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                                                            <Box className="flightairportaddress">
                                                                                <div className="tltdate">{formatDate1(v.OriginDetails.DateTime)}</div>
                                                                                <p>{v.OriginDetails.AirportName + ',' + v.OriginDetails.CityName}</p>
                                                                            </Box>
                                                                        </Col>
                                                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                                                            <Box className="arrowtabtxt">Non-Stop</Box>
                                                                            <Box className="arrowtabinfo"><img src={arrowimg}></img></Box>
                                                                            <Box className="define-timeinfo">{v.SegmentDuration}</Box>
                                                                        </Col>
                                                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                                                            <Box className="flightairportaddress">
                                                                                <div className="tltdate">{formatDate1(v.DestinationDetails.DateTime)}</div>
                                                                                <p>{v.DestinationDetails.CityName + ', ' + v.DestinationDetails.AirportName}</p>
                                                                            </Box>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                                    <Box className="time-calc">{v.SegmentDuration}</Box>
                                                                    {/* <Box className="type">{fsRequestData.v_class}</Box> */}
                                                                    {/* <Box className="seathighighted"><span className="seathightlighted">{v.AvailableSeats} seats left</span> </Box> */}
                                                                </Col>
                                                            </Row>
                                                            <Box className="layoverbox">
                                                                {
                                                                    k < segSumry.length - 1
                                                                      ? <Box className="layover">Layover <span>{v.WaitingTime}</span></Box>
                                                                      : <Box></Box>
                                                                }
                                                            </Box>
                                                        </Box>

                                                    </>
                                                ))}

                                            </>
                                        ))}

                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <Row className="headerRow">
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <Box className="tltBaggage">Type</Box>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <Box className="tltBaggage">Fare</Box>
                                            </Col>
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <Box className="tltBaggage">Total</Box>
                                            </Col>
                                        </Row>

                                        {!!fsRoundReqData.adult && (
                                            <>
                                                <Row>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tltfaredetails"><span className="note-qt">Fare Details for Adult({fareDetails.SegmentSummary[0].AirlineDetails.FareClass}) ({fareDetails.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                            <Box className="tltfaredetails">Base Price</Box>
                                            <Box className="tltfaredetails">Taxes & fee</Box>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <Box className="tltfaredetails">&nbsp;</Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(fareDetails.PassengerFareBreakdown.ADT.BaseFare) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount) } X {fareDetails.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>

                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.ADT.Tax) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) } X {fareDetails.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>
                                        </Col>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tltfaredetails">&nbsp;</Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(fareDetails.PassengerFareBreakdown.ADT.BaseFare)}</span></Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.ADT.Tax) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (fareDetails.PassengerFareBreakdown.ADT.PassengerCount) }</span></Box>
                                        </Col>
                                    </Row>
                                    <Row className="totalRow">
                                        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                            <Box className="tlt-total">Total</Box>
                                        </Col>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(fareDetails.PassengerFareBreakdown.ADT.BaseFare + ((fareDetails.PassengerFareBreakdown.ADT.Tax) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount))) * (fareDetails.PassengerFareBreakdown.ADT.PassengerCount) }</span></Box>
                                        </Col>
                                    </Row>
                                            </>
                                        )}

                                        {!!fsRoundReqData.child && (
                                            <>
                                                <Row>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tltfaredetails"><span className="note-qt">Fare Details for Child({fareDetails.SegmentSummary[0].AirlineDetails.FareClass}) ({fareDetails.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                            <Box className="tltfaredetails">Base Price</Box>
                                            <Box className="tltfaredetails">Taxes & fee</Box>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <Box className="tltfaredetails">&nbsp;</Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(fareDetails.PassengerFareBreakdown.CHD.BaseFare) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount) } X {fareDetails.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>

                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.CHD.Tax) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) } X {fareDetails.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>
                                        </Col>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tltfaredetails">&nbsp;</Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(fareDetails.PassengerFareBreakdown.CHD.BaseFare)}</span></Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.CHD.Tax) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (fareDetails.PassengerFareBreakdown.CHD.PassengerCount) }</span></Box>
                                        </Col>
                                    </Row>
                                    <Row className="totalRow">
                                        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                            <Box className="tlt-total">Total</Box>
                                        </Col>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(fareDetails.PassengerFareBreakdown.CHD.BaseFare + ((fareDetails.PassengerFareBreakdown.CHD.Tax) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount))) * (fareDetails.PassengerFareBreakdown.CHD.PassengerCount) }</span></Box>
                                        </Col>
                                    </Row>
                                            </>
                                        )}

                                        {!!fsRoundReqData.infant && (
                                            <>
                                                <Row>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tltfaredetails"><span className="note-qt">Fare Details for Infant({fareDetails.SegmentSummary[0].AirlineDetails.FareClass}) ({fareDetails.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                            <Box className="tltfaredetails">Base Price</Box>
                                            <Box className="tltfaredetails">Taxes & fee</Box>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <Box className="tltfaredetails">&nbsp;</Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(fareDetails.PassengerFareBreakdown.INF.BaseFare) / (fareDetails.PassengerFareBreakdown.INF.PassengerCount) } X {fareDetails.PassengerFareBreakdown.INF.PassengerCount}</span></Box>

                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.INF.Tax) / (fareDetails.PassengerFareBreakdown.INF.PassengerCount)) } X {fareDetails.PassengerFareBreakdown.INF.PassengerCount}</span></Box>
                                        </Col>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tltfaredetails">&nbsp;</Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(fareDetails.PassengerFareBreakdown.INF.BaseFare)}</span></Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(Math.ceil(fareDetails.PassengerFareBreakdown.INF.Tax))}</span></Box>
                                        </Col>
                                    </Row>
                                    <Row className="totalRow">
                                        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                            <Box className="tlt-total">Total</Box>
                                        </Col>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(fareDetails.PassengerFareBreakdown.INF.BaseFare + fareDetails.PassengerFareBreakdown.INF.Tax)}</span></Box>
                                        </Col>
                                    </Row>
                                            </>
                                        )}

                                    </TabPanel>

                                    <TabPanel value={value} index={2}>
                                        {fareLoader
                                          ? (
                                            <span>Please wait...</span>
                                            )
                                          : (
                                            <>
                                                 {/* <p className="p-font14">{ReactHtmlParser(fareRules)}</p> */}
                                            </>
                                            )}

                                    </TabPanel>

                                    <TabPanel value={value} index={3}>
                                        <Row className="headerRow">
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <Box className="tltBaggage">Sector</Box>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <Box className="tltBaggage">Check-in</Box>
                                            </Col>
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <Box className="tltBaggage">Cabin</Box>
                                            </Col>
                                        </Row>

                                        {props.results.SegmentDetails.map((segSumry, index) => (
                                            <>
                                                {segSumry.map((v, k) => (
                                                    <>
                                                        <Row>
                                                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                                <Box className="tltBaggage-desc">{v.OriginDetails.AirportCode} - {v.DestinationDetails.AirportCode}</Box>
                                                            </Col>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                <Box className="tltBaggage-desc">{v.Baggage}</Box>
                                                            </Col>
                                                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                                <Box className="tltBaggage-desc">{v.CabinBaggage ? v.CabinBaggage : '-----'}</Box>
                                                            </Col>
                                                        </Row>

                                                    </>
                                                ))}
                                            </>
                                        ))}

                                    </TabPanel>
                                    <Box className="closebox" onClick={viewDetailToggle}><CloseIcon /></Box>
                                </Box>

                            )}

                        </Col>
                    </Row>
                </Box>

            </Box>

        </>
  )
}

export default RoundTripInternationalResults

