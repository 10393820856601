import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Box, Container, TextField, Select, MenuItem, FormControl, Button, LinearProgress, Pagination, Stack, Tooltip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { API_BASE_URL, PROFILE_IMAGE } from '../../utility/apiConstants'
import { profileSuccess } from '../../store/user/actions/profileAction'
// import profileImg from "../../../assets/images/images/myprofile.png";
import SideNav from '../../SideNav'
import MainFooter from '../footer/MainFooter'
import { countrylist } from '../../services/countryList';
import { authPost, getAuth, getReq } from '../../utility/apiConfig'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link, useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const CoTravelerList = () => {
    const resultPerPage = 20;
    const navigate = useNavigate();
    const [toggle, setToggle] = useState(false);
    const [userList, setUserList] = useState([]);
    const [initialUserList, setInitialUserList] = useState([]);
    const [coTravelerList, setCoTravelerList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isUserLoading, setIsUserLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedTravelers, setSelectedTravelers] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [currentUserId, setCurrentUserId] = useState(null);
  
   const open = Boolean(anchorEl);
    const [searchDetails, setSearchDetails] = useState({
        firstName: "", lastName: "", travelerName: ""
    });
    const [searchDetailsErr, setSearchDetailsErr] = useState({
        pax_name: null, match: null, status: null, itineraryId: null
    });

    useEffect(() => {
        let scripts = 'https://unpkg.com/libphonenumber-js/bundle/libphonenumber-js.min.js'
        var script = document.createElement("script");
        script.src = scripts
        script.async = true;
        document.body.appendChild(script);
    }, [])

    useEffect(() => {
        getCurrentUserDetails();
    }, [])

    const getCurrentUserDetails = () => {
        getAuth(API_BASE_URL + 'user/account', null)
          .then((response) => {
            let user_data = response.data.data.form_data;
            //console.log("user_data", user_data)
            setCurrentUserId(user_data?.user_id);
            getCoTravelerList(user_data?.user_id);
            getUserList(user_data?.user_id);
          }).catch((error) => {
            console.log(error)
          })
      }

      const getUserList = (user_id) => {
        setIsUserLoading(true);
        try {
            getAuth(API_BASE_URL + `user/list_of_users/0?first_name=&email=&phone=&country_code=&city=&designation=&status=&last_name=&user_id=`)
           // getAuth("https://www.itwgo.in/crew/index.php/" + `user/list_of_users/0/0?user_id=&primary_user_id=1&relationship=&c=1`)
                .then((response) => {
                    if (response.data && response.data.data && response.status) {
                        setUserList(response.data.data.data);
                        setInitialUserList(response.data.data.data);
                        setIsUserLoading(false);
                    }
                    else {
                        setIsUserLoading(false);
                        setUserList([]);
                    }
                }).catch((error) => {
                    alert(error.message);
                    setIsUserLoading(false);
                    setUserList([]);
                })
        } catch (error) {
            setIsUserLoading(false);
        }
    }

    const getSearchedCoTravelerList = (user_id, selected_user_id) => {
        let selected_userId = (selected_user_id !== undefined && selected_user_id !== null) ? selected_user_id : "";
        let first_name = searchDetails.firstName;
        let last_name = searchDetails.lastName;
        setIsLoading(true);
        try {
            getAuth(API_BASE_URL + `user/fetch_co_travelers?first_name=${first_name}&last_name=${last_name}&primary_user_id=${selected_userId}`)
                .then((response) => {
                    if (response.data && response.data.data && response.status) {
                        setCoTravelerList(response.data.data.user_details);
                        setIsLoading(false);
                    }
                    else {
                        setIsLoading(false);
                        setCoTravelerList([]);
                    }
                }).catch((error) => {
                    alert(error.message);
                    setIsLoading(false);
                    setCoTravelerList([]);
                })
        } catch (error) {
            setIsLoading(false);
        }
    }

    // const handleClear = () => {
    //     setSearchDetails({ pax_name: "", match: "", inventoryId: "" });
    //     setSelectedUserId(null);
    //     setRelation('');
    // }

    const getCoTravelerList = (userId) => {
        setIsLoading(true);
        let user_id = userId;
        let first_name = searchDetails.firstName;
        let last_name = searchDetails.lastName
        try {
            getAuth(API_BASE_URL + `user/fetch_co_travelers?first_name=&last_name=&primary_user_id=`)
                .then((response) => {
                    if (response.data && response.data.data && response.status) {
                        setCoTravelerList(response.data.data.user_details);
                        setIsLoading(false);
                    }
                    else {
                        setIsLoading(false);
                        setCoTravelerList([]);
                    }
                }).catch((error) => {
                    console.log("error",error.message);
                    setIsLoading(false);
                    setCoTravelerList([]);
                })
        } catch (error) {
            setIsLoading(false);
        }
    }

    const handleChange = (e) => {
        setSearchDetails({ ...searchDetails, [e.target.name]: e.target.value });
        setSearchDetailsErr({ ...searchDetailsErr, [e.target.name]: null });
    }

    const handleClear = () => {
        setSearchDetails({ firstName: "", lastName: "", travelerName: "" })
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <SideNav header={"Co-Travelers List"} />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Container>
                        <Card className='px-4 py-2 mt-3'>
                            <Row className='mt-3'>
                                <Col xs={12} sm={12} md={8} className='mb-1'>
                                    <h6 className='heading-1'>Co-Travelers List</h6>
                                </Col>
                                <Col xs={12} sm={12} md={4} className='mb-1 d-flex justify-content-end'>
                                    <Button variant='contained' color='primary' size='small' onClick={()=> {setToggle(!toggle)}}><SearchIcon style={{fontSize: 16}} />&nbsp;Search</Button>
                                </Col>
                            </Row>
                            {toggle &&
                            <Card className='p-3 mt-3'>
                                <Row>
                                    <Col xs={12} sm={12} md={3} >
                                        <Box className="setBox ">
                                            <div className="tlthedae">First name</div>
                                            <TextField
                                                autoComplete="given-name"
                                                name="firstName"
                                                required
                                                fullWidth
                                                id="firstName"
                                                placeholder="First name"
                                                // autoFocus
                                                value={searchDetails.firstName}
                                                onChange={handleChange}
                                            />
                                        </Box>
                                    </Col>
                                    <Col xs={12} sm={12} md={3} >
                                        <Box className="setBox ">
                                            <div className="tlthedae">Last name</div>
                                            <TextField
                                                autoComplete="given-name"
                                                name="lastName"
                                                required
                                                fullWidth
                                                id="lastName"
                                                placeholder="Last name"
                                                // autoFocus
                                                value={searchDetails.lastName}
                                                onChange={handleChange}
                                            />
                                        </Box>
                                    </Col>
                                    <Col xs={12} sm={12} md={3} >
                                        <Box className="setBox ">
                                            <div className="tlthedae">Traveler name</div>
                                            <Autocomplete
                                                    id="tags-standard"
                                                    options={initialUserList}
                                                    getOptionLabel={(option) => option.first_name + " " + option.middle_name + " " + option.last_name}
                                                    getOptionSelected={(option, value) => option.id === value.id}
                                                    value={selectedTravelers}
                                                    renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select Travelers"
                                                    />
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        if (newValue !== null) {
                                                            setSelectedTravelers(newValue);
                                                            setSelectedUserId(newValue?.user_id);
                                                        }
                                                    }}
                                                />
                                        </Box>
                                    </Col>
                                    <Col xs={12} sm={12} md={3} className='mt-1'>
                                        <Button variant='contained' size="small" color='success' className='mb-3 mt-3' onClick={()=> getSearchedCoTravelerList(currentUserId, selectedUserId)}>Search</Button>
                                        <Button variant='contained' size="small" color='warning' className='mb-3 mt-3 mx-2' onClick={() => handleClear()}>Clear</Button>
                                    </Col>
                                </Row>
                            </Card>
                            }
                            <Row className='my-3'>
                                {/* <Col xs={12} sm={12} md={12} className='mb-2'>
                                    <h6 className='heading-1'>Hotel Inventory List</h6>
                                </Col> */}
                                <Col xs={12} md={12}>
                                    <TableContainer >
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Traveler Name</TableCell>
                                                <TableCell align="left">Co-Traveler(s) Name</TableCell>
                                                <TableCell align="left">Relationship</TableCell>
                                                <TableCell align="right">Action</TableCell> 
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {isLoading ? (
                                                 <TableRow>
                                                 <TableCell align="left"  colspan="6"> 
                                                     <LinearProgress color="success" />
                                                 </TableCell>
                                             </TableRow>   
                                                ) : (!isLoading && coTravelerList.length > 0) ? (
                                                    <>
                                            {coTravelerList?.map((item,index) => (
                                                <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="left" className='text-capitalize'>{item.p_title + " " + item.p_first_name + " " + item.p_last_name}</TableCell>
                                                    <TableCell align="left" className='text-capitalize'>{item.title + " " + item.first_name + " " + item.middle_name + " " + item.last_name}</TableCell>
                                                    <TableCell align="left" className='text-capitalize'>{item.relationship}</TableCell>
                                                    <TableCell align="right" >
                                                    <Link to={"/update-traveler?" + item?.user_id} className=' btn btn-success'><b>Edit</b></Link>
                                                    </TableCell>
                                               
                                                </TableRow>
                                            ))}
                                            </> ) : (
                                             <TableRow>
                                             <TableCell align="center"  colspan="6"> <p><b>No data found.</b></p></TableCell>
                                             </TableRow>   
                                            )}
                                            {/* {(coTravelerList.length && !isLoading) > 0 ? (
                                                <>
                                            {coTravelerList?.map((item,index) => (
                                                <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="left" className='text-capitalize'>{item.p_title + " " + item.p_first_name + " " + item.p_last_name}</TableCell>
                                                    <TableCell align="left" className='text-capitalize'>{item.title + " " + item.first_name + " " + item.middle_name + " " + item.last_name}</TableCell>
                                                    <TableCell align="left" className='text-capitalize'>{item.relationship}</TableCell>
                                                    <TableCell align="right" >
                                                    <Link to={"/update-traveler?" + item?.user_id} className=' btn btn-success'><b>Edit</b></Link>
                                                    </TableCell>
                                               
                                                </TableRow>
                                            ))}
                                            </>    
                                                ) : (isLoading && coTravelerList.length === 0) ? (
                                                <TableRow>
                                                    <TableCell align="left"  colspan="6"> 
                                                        <LinearProgress color="success" />
                                                    </TableCell>
                                                </TableRow>
                                                ) : (!isLoading && coTravelerList.length === 0) ? (
                                                <TableRow>
                                                <TableCell align="center"  colspan="6"> <p><b>No data found.</b></p></TableCell>
                                                </TableRow>
                                                ): (
                                                    <TableRow>
                                                    <TableCell align="left"  colspan="6"> 
                                                        <LinearProgress color="success" />
                                                    </TableCell>
                                                </TableRow>
                                                )} */}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {totalRecords > 1 && (
                                        <Box className="my-2 px-2 float-right">
                                            <Stack spacing={2}>
                                                <Pagination page={currentPage} count={totalRecords} variant="outlined" color="primary" />
                                            </Stack>
                                        </Box>
                                        )}
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Box>
            </Box>
            <MainFooter />
        </>
    )
}

export default CoTravelerList
