import React, { useLayoutEffect, lazy, Suspense } from 'react';
import { Route, useLocation, Routes } from 'react-router';
import Spinner from './pages/Spinner';
import Login from "./pages/user/Login";
import Registration from "./pages/user/Registration";
import Dashboard from './pages/dashboard/Dashboard';
import Profile from './pages/user/profile/Profile'
import PrivateRoutes from './PrivateRoutes';
import UserApprovalList from './pages/user-approval/UserApprovalList';
import EditProfile from './pages/user/profile/ProfileEdit';
import TravelerItinerary from './pages/traveler-itinerary/TravelerItinerary';
import TravelerItineraryList from './pages/traveler-itinerary/TravelerItineraryList';
import CoTravelerList from './pages/co-traveler/CoTravelerList';
import CoTravelerDetails from './pages/co-traveler/CoTravelerDetails';
import UpdateTraveler from './pages/co-traveler/UpdateTraveler';

const LoginRoutes = () => {
  const location = useLocation()
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" exact element={<Dashboard />}></Route>
            <Route path="/profile" element={<Profile />}></Route>
            <Route path="/edit-profile" element={<EditProfile />}></Route>
            <Route path="/user-approval-list" element={<UserApprovalList />}></Route>
            <Route path="/traveler-itinerary-list" element={<TravelerItineraryList />}></Route>
            <Route path="/traveler-itinerary" element={<TravelerItinerary />}></Route>
            <Route path="/co-travelers-list" element={<CoTravelerList />}></Route>
            <Route path="/co-traveler-details" element={<CoTravelerDetails />}></Route>
            <Route path="/update-traveler" element={<UpdateTraveler />}></Route>
          </Route>
          <Route path="/login" element={<Login />}></Route>
          {/* <Route path="/sign-up" element={<Registration />}></Route> */}
          <Route path="/" element={<Login />}></Route>
        </Routes>
      </Suspense>
    </div>
  )
}

export default LoginRoutes
