import React, {useEffect, useState} from 'react';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import './dashboard.css'
import SideNav from '../../SideNav';
import { Box } from '@mui/material';
import MainFooter from '../footer/MainFooter';
import { getAuth } from '../../utility/apiConfig';
import { API_BASE_URL } from '../../utility/apiConstants';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard() {
    const [loading, setLoading] = useState(false);
    const [dashBoardData, setDashBoardData] = useState(null);

    useEffect(() => {
        getUserAccountDetails();
        getDashboardDetails();
    }, []);

    const getUserAccountDetails = () => {
        setLoading(true);
        try {
            getAuth(API_BASE_URL + 'user/account')
                .then((response) => {
                    // console.log("response=======>",response.data);
                    if (response.data.status) {
                        // alert(response.data.data.message)
                      //  console.log("response=======>", response.data.data?.form_data);
                        let user_data = response.data.data?.form_data
                        localStorage.setItem('user_data', JSON.stringify(user_data));
                        setLoading(false);
                    } else {
                        alert(response.data.message)
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log("Error", error)
                   // alert('Somthing went wrong please try again')
                    setLoading(false);
                })
        } catch (error) {
        }
    }

    const getDashboardDetails = () => {
        setLoading(true);
        try {
            getAuth(API_BASE_URL + 'data/dashboard')
                .then((response) => {
                    // console.log("response=======>",response.data);
                    if (response.data.status) {
                        // alert(response.data.data.message)
                       // console.log("response=======>", response.data);
                        setDashBoardData(response.data?.data);
                        setLoading(false);
                    } else {
                        alert(response.data.message)
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log("Error", error)
                   // alert('Somthing went wrong please try again')
                    setLoading(false);
                })
        } catch (error) {
        }
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <SideNav header={"Dashboard"} />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Container maxWidth="lg" sx={{ mt: 3, }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={3}>
                                <div className="card bg-c-blue order-card">
                                    <div className="card-block">
                                        <h6 className="m-b-20">Users</h6>
                                        <h2 className=""><span>{dashBoardData?.total_users}</span></h2>
                                        {/* <p className="mb-0">Total Users</p> */}
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <div className="card bg-c-green order-card">
                                    <div className="card-block">
                                        <h6 className="m-b-20">Active users</h6>
                                        <h2 className=""><span>{dashBoardData?.active_users}</span></h2>
                                        {/* <p className="mb-0">Completed Request</p> */}
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <div className="card bg-c-yellow order-card">
                                    <div className="card-block">
                                        <h6 className="m-b-20">Itineraries Created</h6>
                                        <h2 className=""><span>{dashBoardData?.itineraries_created}</span></h2>
                                        {/* <p className="mb-0">Pending Request</p> */}
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <div className="card bg-c-pink order-card">
                                    <div className="card-block">
                                        <h6 className="m-b-20">Inactive Users</h6>
                                        <h2 className=""><span>{dashBoardData?.inactive_users}</span></h2>
                                        {/* <p className="mb-0">Rejected Request</p> */}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
            <MainFooter />
        </>
    );
}