import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Checkbox, FormControlLabel, Radio, RadioGroup, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
// import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../../assets/airlines/airlineLogoSprites.css';
import arrowimg from '../../../assets/images/arrow.svg';
import arrowFlight from '../../../assets/images/arrowflight.png';
import luggage from '../../../assets/images/luggage.svg';
import nonrefund from '../../../assets/images/nonrefund.svg';
import refund from '../../../assets/images/refund.svg';
import seat from '../../../assets/images/seat.svg';
import { stopsCount } from '../../../core/airlineStops';
import { flightDate, formatDate, formatDate1, toDigit } from '../../../core/helper';
import { ssrRequest } from '../../../store/flights/actions/flightBookingSSRActions';
import { authPost } from '../../../utility/apiConfig';
import { API_BASE_URL } from '../../../utility/apiConstants';
import './oneWayResults.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment';

/// fare identifier radio box
const Regular = withStyles({
  root: {
    color: '#fc9e15',
    '&$checked': {
      color: '#fc9e15'
    }
  }
})(props => <Radio color="default" {...props} />)

const Tactical = withStyles({
  root: {
    color: '#e91e63',
    '&$checked': {
      color: '#e91e63'
    }
  }
})(props => <Radio color="default" {...props} />)

const SME = withStyles({
  root: {
    color: '#3A62D9',
    '&$checked': {
      color: '#3A62D9'
    }
  }
})(props => <Radio color="default" {...props} />)

const Deal = withStyles({
  root: {
    color: '#ff0000',
    '&$checked': {
      color: '#ff0000'
    }
  }
})(props => <Radio color="default" {...props} />)

const Corporate = withStyles({
  root: {
    color: '#28a745',
    '&$checked': {
      color: '#28a745'
    }
  }
})(props => <Radio color="default" {...props} />)

const Others = withStyles({
  root: {
    color: '#17a2b8',
    '&$checked': {
      color: '#17a2b8'
    }
  }
})(props => <Radio color="default" {...props} />)

function FareidentifierRadio(fareType) {
  switch (fareType) {
    case 'Regular':
      return (<Regular />)
      break

    case 'Tactical':
      return (<Tactical />)
      break

    case 'SME':
      return (<SME />)
      break

    case 'Deal':
      return (<Deal />)
      break

    case 'Refundable':
      return (<Corporate />)
      break

    default:
      return (<Others />)
      break
  }
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    //   backgroundColor: '#f5f5f9',
    //   color: 'rgba(0, 0, 0, 0.87)',
    //   maxWidth: 220,
    //   fontSize: theme.typography.pxToRem(12),
    //   border: '1px solid #dadde9',
  }
}))(Tooltip)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function viewDetailsToggle(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}))

const OneWayResults = (props) => {
 const classes = useStyles()
  const dispatch = useDispatch()
  const history = useNavigate()

  const { fsData, fsRequestData, fsInvData } = useSelector(state => state.flightResult)
  const { fsOneWayGDS, fsOneWayTBO, fsOneWay6E, fsOneWay6ECorp } = useSelector(state => state.flightResult)

  const filters = useSelector(state => state.filter)

  const segments = props.results[0].SegmentDetails[0]
  const segSummery = props.results[0].SegmentSummary[0]
  const airlines = segments[0].AirlineDetails

  const [value, setValue] = useState(0)
  const [fareValues, setFareValues] = useState('0')
  const [fareDetails, setFareDetails] = useState({})
  const [fareRules, setFareRules] = useState(null)
  const [fareLoader, setFareLoader] = useState(false)

  const [paxCount, setpaxCount] = useState(props.reqDetails.adult + props.reqDetails.child)
  const [toggle, setToggle] = useState(false)
  const [toggleMoreFlight, setToggleMoreFlight] = useState(false)

  const handleFlighttabsChange = (event, newValue) => {
   setValue(newValue)

    if (newValue === 2) {
      getFareRules(props.results[0])
    }
  }

  const viewDetailToggle = (item, type) => {
    if(type === "1"){
    setToggle(true)
    setFareDetails(props.results[fareValues])
    setValue(0)
    setToggleMoreFlight(false)
    }
    else if(type === "2"){
    setToggle(true)
    setFareDetails(item)
    setValue(0)
    setToggleMoreFlight(false)
    }
    else{
    setToggle(false)
    setValue(0)
    setToggleMoreFlight(false)
    }
    setToggle(!toggle)
  }

  const moreFlightToggle = () => {
    setToggleMoreFlight(!toggleMoreFlight)
    setFareDetails(props.results[fareValues])
    setValue(0)
    setToggle(false)
  }

  const handleChangeFares = (event) => {
    setFareValues(event.target.value)
    setFareDetails(props.results[event.target.value])
    if(toggle && value === 2)
    {
      getFareRules(props.results[event.target.value])
    }
  }

  const getFareRules = useCallback(
    (data) => {
      setFareLoader(true)
     
      const trt = data?.iti_origin

      const obj = {}
      if (trt !== undefined) {
        obj.data_access_key = data.Token
        obj.booking_source = data.booking_source
        obj.search_access_key = data.ProvabAuthKey
        obj.provab_auth_key = 'undefined'
        obj.iti_origin = trt
      } else {
        obj.data_access_key = data.Token
        obj.booking_source = data.booking_source
        obj.search_access_key = data.ProvabAuthKey
        obj.provab_auth_key = 'undefined'
      }

      try {
        authPost(API_BASE_URL + 'ajax/get_fare_details', obj)
          .then((response) => {
            setFareLoader(false)
            if (response.data.status) {
              
              const info = response.data.data[0]?.fare_rules
              if (info !== undefined) {
                const iic = info.concat(response.data.data[0]?.cancellation_policy)
                setFareRules(iic)
              } else {
                setFareRules(response.data.data.data[0].FareRules)
              }
            } else {
              setFareRules(response.data.data.msg)
            }
          }).catch((error) => {
            setFareLoader(false)
            setFareRules("Fare details not available at this moment.")
          })
      } catch (error) {
        setFareLoader(false)
        setFareRules('Somthing went wrong please try again...')
      }
    }
  )

  const flightBooking = (flightData, index) => {
    let results = (index !== undefined && index !== null) ? props.results[index] : props.results[0]
    const fs = props.results[index]
    const flifgtEnv = fs?.iti_origin

    const obj = {}
    if (flifgtEnv !== undefined && fs.booking_source == "PTBSID0000002021") {
      obj.is_domestic = fsInvData.data.data.IsDomestic
      obj.token = [fs.Token]
      obj.token_key = [fs.TokenKey]
      obj.search_access_key = [fs.ProvabAuthKey]
      obj.promotional_plan_type = ['']
      obj.booking_source = [fs.booking_source]
      obj.alternate_flights = ''
      obj.search_hash = fsInvData.data.session_expiry_details.search_hash
      obj.iti_origin = [flifgtEnv]

      dispatch(ssrRequest(obj))

      const data = {
        adult: props.reqDetails.adult,
        child: props.reqDetails.child,
        infant: props.reqDetails.infant,
        isDomestic: fsInvData && fsInvData.data && fsInvData.data.data.IsDomestic,
        domestic_round_way_flight: fsInvData.data.data.domestic_round_way_flight
      }

      // history.push({
      //   pathname: '/flight/booking',
      //   state: [obj, fsInvData.data.data.search_id, fs, fsInvData.data.data.cabin_class, data]
      // })
      props.setModalFlight(false);
      props.setOnewayFlight(fs);
      props.setOnwardFlight(null);
      props.setReturnFlight(null);
      props.setIsInt(false);

    } else {
      const flightdata = checkFlightData()
      obj.is_domestic = flightdata.data.IsDomestic
      obj.token = [fs.Token]
      obj.token_key = [fs.TokenKey]
      obj.search_access_key = [fs.ProvabAuthKey]
      obj.promotional_plan_type = ['']
      obj.booking_source = [fs.booking_source]
      obj.alternate_flights = ''
      obj.search_hash = flightdata.session_expiry_details.search_hash

      dispatch(ssrRequest(obj))

      const data = {
        adult: props.reqDetails.adult,
        child: props.reqDetails.child,
        infant: props.reqDetails.infant,
        isDomestic: flightdata.data.IsDomestic,
        domestic_round_way_flight: flightdata.data.domestic_round_way_flight
      }

      // history.push({
      //   pathname: '/flight/booking',
      //   state: [obj, flightdata.data.search_id, fs, flightdata.data.cabin_class, data]
      // })

      props.setModalFlight(false);
      props.setOnewayFlight(fs);
      props.setOnwardFlight(null);
      props.setReturnFlight(null);
    }
  }

  // function to check if data is present in flight api
  const checkFlightData = () => {
    if (fsOneWayGDS && fsOneWayGDS.data) {
      return fsOneWayGDS
    } else if (fsOneWay6E && fsOneWay6E.data) {
      return fsOneWay6E
    } else if (fsOneWayTBO && fsOneWayTBO.data) {
      return fsOneWayTBO
    } else if (fsOneWay6ECorp && fsOneWay6ECorp.data) {
      return fsOneWay6ECorp
    } else {
      return fsInvData
    }
  }

  useEffect(() => {
    setToggle(false)
    setValue(0)
   // setFareValues('0')
  }, [props])

  let minDuaration =  Math.min(...props.paginationData.map(x=> x.map(y=> y.SegmentSummary[0].TotalDuaration_min)).flat());
  let minimum = props.paginationData.map(x=> x.find(y=> y.SegmentSummary[0].TotalDuaration_min === minDuaration)).filter(z=> z !== undefined)[0].ProvabAuthKey;
  let maxDuaration =  Math.max(...props.paginationData.map(x=> x.map(y=> y.SegmentSummary[0].TotalDuaration_min)).flat());
  let maximum = props.paginationData.map(x=> x.find(y=> y.SegmentSummary[0].TotalDuaration_min === maxDuaration)).filter(z=> z !== undefined)[0].ProvabAuthKey;
  return (
    
    <Box>
      <Box className="bgflightdetailscard">
        {((props.results.map(x=> x.Attr.Faretype).indexOf("Deal") === -1) && (
          maximum !== undefined && props.results.map(x=> x.ProvabAuthKey).indexOf(maximum) === -1
        ) &&
            minimum !== undefined && props.results.map(x=> x.ProvabAuthKey).indexOf(minimum) !== -1) && (
         <Box>
          <span className='info-label shortdistance-info-bg'>Shortest Duration</span>
          <span className='info-label-1 '> </span>
        </Box>
        )}
        {((props.results.map(x=> x.Attr.Faretype).indexOf("Deal") === -1) &&
          maximum !== undefined && props.results.map(x=> x.ProvabAuthKey).indexOf(maximum) !== -1) && (
            minimum !== undefined && props.results.map(x=> x.ProvabAuthKey).indexOf(minimum) === -1
          ) && (
         <Box>
          <span className='info-label longdistance-info-bg'>Longest Duration</span>
          <span className='info-label-1 '> </span>
        </Box>
        )}
        {(props.results.map(x=> x.Attr.Faretype).indexOf("Deal") !== -1) && (
         <Box>
          <span className='info-label dealavailable-info-bg'>Deal available</span>
          <span className='info-label-1 '> </span>
        </Box>
        )}
        <Row className="viewresultbooking">
          <Col xs={5} sm={4} md={2} lg={2} xl={2} className="zerogutter m-auto">
            <Box className="flightimg">
              <Box className='imgminifly'>
                <Box className={'itwgo-60x35_' + airlines.AirlineCode}></Box>
                {/* <img  className={'itwgo-60x35_'+airlines.AirlineCode}/> */}
              </Box>
              <Box className="mini-tag">
                {/* <Box className="flightname">{props.results[0].SegmentSummary[0].AirlineDetails.AirlineName}</Box> */}
                <Box className="flightname">{props.results[0].SegmentSummary[0].AirlineDetails.AirlineName}</Box>
                <Box className="flightnumber">{props.results[0].SegmentSummary[0].AirlineDetails.AirlineCode + ' ' + props.results[0].SegmentSummary[0].AirlineDetails.FlightNumber}</Box>
              </Box>
            </Box>
            <Box className="seats-count"><Tooltip title={`${props.results[0].SegmentDetails[0][0].AvailableSeats} seats left at this price`} arrow placement="top"><span className="available-seats">{props.results[0].SegmentDetails[0][0].AvailableSeats} Seats</span></Tooltip></Box>
            
          </Col>
          <Col xs={7} sm={8} md={5} lg={5} xl={5} className="">
            <Box className="flexset">
              <Box className="definewidth">
                <Box className="flightmininame">{props.results[0].SegmentSummary[0].OriginDetails.CityName}</Box>
                <Box className="flightminitime">{props.results[0].SegmentSummary[0].OriginDetails._DateTime}</Box>
                <Box className="flightminidata">{flightDate(props.results[0].SegmentSummary[0].OriginDetails._Date)}</Box>
              </Box>
              <Box className="arrowbox ">
                <Box className="define-stop">{stopsCount[props.results[0].SegmentSummary[0].TotalStops]}</Box>
                <Box className="arrow-img"> <span className='arrow-line'></span><img src={arrowFlight}></img></Box>
                <Box className="define-timecalc">{props.results[0].SegmentSummary[0].TotalDuaration}</Box>
              </Box>
              <Box className="definewidth">
                <Box className="flightmininame">{segments[props.results[0].SegmentSummary[0].TotalStops].DestinationDetails.CityName}</Box>
                <Box className="flightminitime">{segments[props.results[0].SegmentSummary[0].TotalStops].DestinationDetails._DateTime}</Box>
                <Box className="flightminidata">{flightDate(segments[props.results[0].SegmentSummary[0].TotalStops].DestinationDetails._Date)}</Box>
              </Box>
            </Box>
          </Col>
          <Col xs={9} sm={9} md={3} lg={3} xl={3} className="zerogap m-auto">
            {props.showWhatsApp && (
            <Box className="share-cbx ">
              <Checkbox 
                className='share-checkbox'
                checked={props.selectedFlight.length !== 0 ? (props.selectedFlight.indexOf(props.results[0].ProvabAuthKey) > -1 ? true : false) : false}
                onChange={()=> props.onToggleSelectedFlight(props.results[0].SegmentSummary[0],
                                props.results[0].SegmentSummary[0].OriginDetails, 
                                segments[props.results[0].SegmentSummary[0].TotalStops].DestinationDetails, 
                                props.results[0].Attr, 
                                Math.ceil(props.results[0].FareDetails.b2b_PriceDetails._CustomerBuying), 
                                props.results[0])}
                />
            </Box>
            )}
            <Box className="radioBox">
              <Box row aria-label="position" name="position" defaultValue="top"
                orientation="vertical"
                value={fareValues}
                onChange={handleChangeFares}
              >
                {/* <ul className="servicesBox">
                  {console.log(props)}
                  {props.results.map((value, index) => (
                    <li key={index}> */}

                    <Box className="servicesBox">
                        <Row>
                          <Col md={8} xs={8} className="servicesBoxs d-flex">
                            <Box className='pricewidth'>
                              <Box className="highlight-price"><span>	&#x20B9; </span>{Math.ceil(props.results[0].FareDetails.b2b_PriceDetails._CustomerBuying)}
                              &nbsp;<Tooltip onClick={()=>viewDetailToggle(props.results[0], "1")} title="Fare Rules" arrow placement="top" ><InfoOutlinedIcon className='fare-rule-icon' /></Tooltip>
                              </Box>
                              {/* <Box className="itemssecttag">{props.results[0].Attr.Faretype}</Box> */}
                              {/* <Box className="itemssecttag-supplier">{props.results[0].supplierName}</Box> */}
                            </Box>
                            {/* {props.showNetFare && (
                              <Box className='pricewidth'>
                           
                                  <HtmlTooltip
                                    title={
                                      <Box>
                                        Commision: {props.results[0].FareDetails.b2b_PriceDetails._Commission} <br />
                                        Markup: {props.results[0].FareDetails.b2b_PriceDetails._Markup}
                                      </Box>
                                    } arrow placement="right">
                                    <Box className="shownet"><span>	&#x20B9; </span> {Math.ceil(props.results[0].FareDetails.b2b_PriceDetails._AgentBuying)}</Box>
                                  </HtmlTooltip>
                              </Box>
                             )} */}
                          </Col>  
                          {/* <Col md={4} xs={4} className="servicesBoxs"> 
                            <ul className="listicon">

                              <li className="listitemsicon">
                                <HtmlTooltip title={
                                  <React.Fragment>
                                    Check-In Baggage {props.results[0].SegmentDetails[0][0].Baggage} <br />
                                    Cabin Baggage {props.results[0].SegmentDetails[0][0].CabinBaggage}
                                  </React.Fragment>
                                } arrow placement="top">
                                  <img src={luggage}></img>
                                </HtmlTooltip>
                              </li>

                              {props.results[0].Attr.IsRefundable
                                ? (
                                  <li className="listitemsicon"><Tooltip title="Refundable" arrow placement="top"><img src={refund}></img></Tooltip></li>
                                )
                                : (
                                  <li className="listitemsicon"><Tooltip title="Non-Refundable" arrow placement="top"><img src={nonrefund}></img></Tooltip></li>
                                )}
                              <li className="listitemsicon"><Tooltip title={`Available seats ${props.results[0].SegmentDetails[0][0].AvailableSeats}`} arrow placement="top"><img src={seat}></img></Tooltip></li>
                              
                            </ul>
                        </Col> */}
                      </Row>
                    </Box>

                      

                    {/* </li>
                  ))}

                </ul> */}
              </Box>
            </Box>
           
          </Col>
          <Col xs={3} sm={3} md={2} lg={2} xl={2}>
            
            <Button className="btnBook" variant="contained"
              onClick={() => flightBooking(fareDetails, Number(0))}
            >Select</Button>

            {/* <Box className="share-cbx">
              <div className='share-icon-box'>
                <span><WhatsAppIcon className='whatsapp-icon' /> </span>
                <span  className='share-txtbtn'>Share</span>
              </div>
              <Checkbox 
                className='share-checkbox'
                checked={props.selectedFlight.length !== 0 ? (props.selectedFlight.indexOf(props.results[JSON.parse(fareValues)].ProvabAuthKey) > -1 ? true : false) : false}
                onChange={()=> props.onToggleSelectedFlight(props.results[JSON.parse(fareValues)].SegmentSummary[0],
                                props.results[JSON.parse(fareValues)].SegmentSummary[0].OriginDetails, 
                                segments[props.results[JSON.parse(fareValues)].SegmentSummary[0].TotalStops].DestinationDetails, 
                                props.results[JSON.parse(fareValues)].Attr, 
                                Math.ceil(props.results[JSON.parse(fareValues)].FareDetails.b2b_PriceDetails._CustomerBuying), 
                                props.results[JSON.parse(fareValues)])}
                />
            </Box> */}
          </Col>
        </Row>
        <Row className='viewmore-box'>
          <Col md={7} xs={0}>
          </Col>
          <Col md={3} xs={7} className="text-right">
            <Box className="">
              {props.results.length > 1 && (
              <Box className="more-flights-btn" variant="contained" color="primary" onClick={moreFlightToggle}>More Flights ({ props.results.length - 1 }) {toggleMoreFlight ? <KeyboardArrowDownIcon className='arrow-down-rotate' />  : <KeyboardArrowDownIcon /> } </Box>
              )}
              </Box>
          </Col>
          <Col md={2} xs={5}>
            <Box className="viewdetailwrp">
              <Box className="view-more-btn" variant="contained" color="primary" onClick={()=>viewDetailToggle(props.results[0], "1")}>View Details {toggle ? <KeyboardArrowDownIcon className='arrow-down-rotate' /> : <KeyboardArrowDownIcon />  }</Box>
            </Box>
          </Col>
        </Row>

        <Box>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="zerogap">

              {toggle && (
                <Box className="tabsBox">
                  <Tabs className="flighttabsdetails" value={value} onChange={handleFlighttabsChange} aria-label="simple tabs example">
                    <Tab className="flighttabsdetails-link" label="flight details" {...viewDetailsToggle(0)} />
                    <Tab className="flighttabsdetails-link" label="fare details" {...viewDetailsToggle(1)} />
                    <Tab className="flighttabsdetails-link" label="Fare Rules" {...viewDetailsToggle(2)} />
                    <Tab className="flighttabsdetails-link" label="baggage information" {...viewDetailsToggle(3)} />
                  </Tabs>
                  {/* <Box className="tltfaretype-desc">Fare Type: 
                          <span className="tltBaggage-sm"> &nbsp;{fareDetails.Attr.Faretype}</span>
                          </Box> */}
                  <TabPanel className="tabpanelsflightpanel" value={value} index={0}>

                    {props.results[0].SegmentDetails[0].map((value, index) => (

                      <Box className="tabcontentsbox">
                        <Box className="to-fromtlt">
                          <span className="fromname">{value.OriginDetails.CityName} </span>
                          <span className="icoarrowfromto">
                            <img src={arrowimg}></img>
                          </span>
                          <span className="toname">{value.DestinationDetails.CityName}</span>
                          <span className="dateformto">{formatDate(value.OriginDetails.DateTime)}</span>
                        </Box>
                        <Row>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="flightimg">
                              <Box className="imgminifly">
                                <img className={'itwgo-60x35_' + value.AirlineDetails.AirlineCode} />
                              </Box>
                              <Box className="mini-tag">
                                <Box className="flightname">{value.AirlineDetails.AirlineName}</Box>
                                <Box className="flightnumber">{value.AirlineDetails.AirlineCode}-{value.AirlineDetails.FlightNumber}</Box>
                              </Box>
                            </Box>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Row>
                              <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                <Box className="flightairportaddress">
                                  <div className="tltdate">{formatDate1(value.OriginDetails.DateTime)}</div>
                                  <p className='mb-0'>{value.OriginDetails.AirportName}, {value.OriginDetails.CityName}</p>
                                  {value?.OriginDetails?.Terminal ? <p>Terminal {value.OriginDetails.Terminal}</p> : null }
                                </Box>
                              </Col>
                              <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap m-inherit">
                                <Box className="arrowtabtxt">Non-Stop</Box>
                                <Box className="arrowtabinfo"><img src={arrowimg}></img></Box>
                                <Box className="define-timeinfo">{value.SegmentDuration}</Box>
                              </Col>
                              <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                <Box className="flightairportaddress">
                                  <div className="tltdate">{formatDate1(value.DestinationDetails.DateTime)}</div>
                                  <p className='mb-0'>{value.DestinationDetails.AirportName}, {value.DestinationDetails.CityName}</p>
                                  {value?.DestinationDetails?.Terminal ? <p>Terminal {value.DestinationDetails.Terminal}</p> : null }

                                </Box>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="time-calc">{value.SegmentDuration}</Box>
                            <Box className="type">{fsRequestData.v_class}</Box>
                            {/* <Box className="seathighighted"><span className="seathightlighted">{value.AvailableSeats} seats left</span> </Box> */}
                          </Col>
                        </Row>
                        <Box className="layoverbox">
                          {
                            index < props.results[0].SegmentDetails[0].length - 1
                              ? <Box className="layover">Layover <span>{value.WaitingTime}</span></Box>
                              : <Box></Box>
                          }
                        </Box>
                      </Box>
                    ))
                    }

                  </TabPanel>

                  <TabPanel value={value} index={1}>
                    <Row className="headerRow">
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Box className="tltBaggage">Type</Box>
                      </Col>
                      <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                        <Box className="tltBaggage">Fare</Box>
                      </Col>
                      <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                        <Box className="tltBaggage">Total</Box>
                      </Col>
                    </Row>

                    {!!fsRequestData.adult && (
                      <>
                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                            <Box className="tltfaredetails"><span className="note-qt">Fare Details for Adult({fareDetails.SegmentSummary[0].AirlineDetails.FareClass}) ({fareDetails.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                            <Box className="tltfaredetails">Base Price</Box>
                            <Box className="tltfaredetails">Taxes & fee</Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="tltfaredetails">&nbsp;</Box>
                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(fareDetails.PassengerFareBreakdown.ADT.BaseFare) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount)} X {fareDetails.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>

                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.ADT.Tax) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount))} X {fareDetails.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                            <Box className="tltfaredetails">&nbsp;</Box>
                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(fareDetails.PassengerFareBreakdown.ADT.BaseFare)}</span></Box>
                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.ADT.Tax) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (fareDetails.PassengerFareBreakdown.ADT.PassengerCount)}</span></Box>
                          </Col>
                        </Row>
                        <Row className="totalRow">
                          <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                            <Box className="tlt-total">Total</Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                            <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(fareDetails.PassengerFareBreakdown.ADT.BaseFare + ((fareDetails.PassengerFareBreakdown.ADT.Tax) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (fareDetails.PassengerFareBreakdown.ADT.PassengerCount))}</span></Box>
                          </Col>
                        </Row>
                      </>
                    )}

                    {!!fsRequestData.child && (
                      <>
                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Box className="tltfaredetails"><span className="note-qt">Fare Details for Child({fareDetails.SegmentSummary[0].AirlineDetails.FareClass}) ({fareDetails.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                            <Box className="tltfaredetails">Base Price</Box>
                            <Box className="tltfaredetails">Taxes & fee</Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="tltfaredetails">&nbsp;</Box>
                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(fareDetails.PassengerFareBreakdown.CHD.BaseFare) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount)} X {fareDetails.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>

                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.CHD.Tax) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount))} X {fareDetails.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                            <Box className="tltfaredetails">&nbsp;</Box>
                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(fareDetails.PassengerFareBreakdown.CHD.BaseFare)}</span></Box>
                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.CHD.Tax) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (fareDetails.PassengerFareBreakdown.CHD.PassengerCount)}</span></Box>
                          </Col>
                        </Row>
                        <Row className="totalRow">
                          <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                            <Box className="tlt-total">Total</Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                          <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(fareDetails.PassengerFareBreakdown.CHD.BaseFare + ((fareDetails.PassengerFareBreakdown.CHD.Tax) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (fareDetails.PassengerFareBreakdown.CHD.PassengerCount))}</span></Box>
                          </Col>
                        </Row>
                      </>
                    )}

                    {!!fsRequestData.infant && (
                      <>
                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Box className="tltfaredetails"><span className="note-qt">Fare Details for Infant({fareDetails.SegmentSummary[0].AirlineDetails.FareClass}) ({fareDetails.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                            <Box className="tltfaredetails">Base Price</Box>
                            <Box className="tltfaredetails">Taxes & fee</Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="tltfaredetails">&nbsp;</Box>
                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(fareDetails.PassengerFareBreakdown.INF.BaseFare) / (fareDetails.PassengerFareBreakdown.INF.PassengerCount)} X {fareDetails.PassengerFareBreakdown.INF.PassengerCount}</span></Box>

                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.INF.Tax) / (fareDetails.PassengerFareBreakdown.INF.PassengerCount))} X {fareDetails.PassengerFareBreakdown.INF.PassengerCount}</span></Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                            <Box className="tltfaredetails">&nbsp;</Box>
                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(fareDetails.PassengerFareBreakdown.INF.BaseFare)}</span></Box>
                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(Math.ceil(fareDetails.PassengerFareBreakdown.INF.Tax))}</span></Box>
                          </Col>
                        </Row>
                        <Row className="totalRow">
                          <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                            <Box className="tlt-total">Total</Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                          <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(fareDetails.PassengerFareBreakdown.INF.BaseFare + ((fareDetails.PassengerFareBreakdown.INF.Tax) / (fareDetails.PassengerFareBreakdown.INF.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (fareDetails.PassengerFareBreakdown.INF.PassengerCount))}</span></Box>
                          </Col>
                        </Row>
                      </>
                    )}

                  </TabPanel>

                  <TabPanel value={value} index={2}>

                    {fareLoader
                      ? (
                        <span>Please wait...</span>
                      )
                      : (
                        <>
                          {/* <p className="p-font14">{ReactHtmlParser(fareRules)}</p> */}
                        </>
                      )}

                  </TabPanel>

                  <TabPanel value={value} index={3}>
                    <>
                      <Row className="headerRow">
                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                          <Box className="tltBaggage">Sector</Box>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Box className="tltBaggage">Check-in</Box>
                        </Col>
                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                          <Box className="tltBaggage">Cabin</Box>
                        </Col>
                      </Row>

                      {fareDetails.SegmentDetails[0].map((value, index) => (
                        <Row>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="tltBaggage-desc">{value.OriginDetails.AirportCode} - {value.DestinationDetails.AirportCode}</Box>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Box className="tltBaggage-desc">{value.Baggage}</Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="tltBaggage-desc">{value.CabinBaggage}</Box>
                          </Col>
                        </Row>
                      ))}

                    </>
                  </TabPanel>

               

                  <Box className="closebox" onClick={()=>viewDetailToggle(props.results[0], "3")}><CloseIcon /></Box>
                </Box>

              )}

            </Col>
          </Row>
        </Box>

        <Box className='more-flight-container'>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {toggleMoreFlight && (
                <Box>
                  <ul className="servicesBox">
                  {props.results.slice(1).map((value, index) => (
                    <li key={index}>
                    <Row className="viewresultbooking">
                      <Col xs={5} sm={4} md={2} lg={2} xl={2} className="zerogutter m-auto">
                        <Box className="flightimg">
                          <Box className='imgminifly'>
                            <Box className={'itwgo-60x35_' + airlines.AirlineCode}></Box>
                            {/* <img  className={'itwgo-60x35_'+airlines.AirlineCode}/> */}
                          </Box>
                          <Box className="mini-tag">
                            {/* <Box className="flightname">{props.results[0].SegmentSummary[0].AirlineDetails.AirlineName}</Box> */}
                            <Box className="flightname">{props.results[0].SegmentSummary[0].AirlineDetails.AirlineName}</Box>
                            <Box className="flightnumber">{props.results[0].SegmentSummary[0].AirlineDetails.AirlineCode + ' ' + props.results[0].SegmentSummary[0].AirlineDetails.FlightNumber}</Box>
                          </Box>
                        </Box>
                        <Box className="seats-count"><Tooltip title={`${value.SegmentDetails[0][0].AvailableSeats} seats left at this price`} arrow placement="top"><span className="available-seats">{value.SegmentDetails[0][0].AvailableSeats} Seats</span></Tooltip></Box>
                        
                      </Col>
                      <Col xs={7} sm={8} md={5} lg={5} xl={5} className="zerogutter">
                        <Box className="flexset">
                          <Box className="definewidth">
                            <Box className="flightmininame">{props.results[0].SegmentSummary[0].OriginDetails.CityName}</Box>
                            <Box className="flightminitime">{props.results[0].SegmentSummary[0].OriginDetails._DateTime}</Box>
                            <Box className="flightminidata">{flightDate(props.results[0].SegmentSummary[0].OriginDetails._Date)}</Box>
                          </Box>
                          <Box className="arrowbox ">
                            <Box className="define-stop">{stopsCount[props.results[0].SegmentSummary[0].TotalStops]}</Box>
                            <Box className="arrow-img"><span className='arrow-line'></span><img src={arrowFlight}></img></Box>
                            <Box className="define-timecalc">{props.results[0].SegmentSummary[0].TotalDuaration}</Box>
                          </Box>
                          <Box className="definewidth">
                            <Box className="flightmininame">{segments[props.results[0].SegmentSummary[0].TotalStops].DestinationDetails.CityName}</Box>
                            <Box className="flightminitime">{segments[props.results[0].SegmentSummary[0].TotalStops].DestinationDetails._DateTime}</Box>
                            <Box className="flightminidata">{flightDate(segments[props.results[0].SegmentSummary[0].TotalStops].DestinationDetails._Date)}</Box>
                          </Box>
                        </Box>
                      </Col>
                      <Col xs={9} sm={9} md={3} lg={3} xl={3} className="zerogap m-auto">
                        {props.showWhatsApp && (
                          <Box className="share-cbx ">
                            <Checkbox 
                              className='share-checkbox'
                              checked={props.selectedFlight.length !== 0 ? (props.selectedFlight.indexOf(props.results.slice(1)[index].ProvabAuthKey) > -1 ? true : false) : false}
                              onChange={()=> props.onToggleSelectedFlight(props.results.slice(1)[index].SegmentSummary[0],
                                              props.results.slice(1)[index].SegmentSummary[0].OriginDetails, 
                                              segments[props.results.slice(1)[index].SegmentSummary[0].TotalStops].DestinationDetails, 
                                              props.results.slice(1)[index].Attr, 
                                              Math.ceil(props.results.slice(1)[index].FareDetails.b2b_PriceDetails._CustomerBuying), 
                                              props.results.slice(1)[index])}
                              />
                          </Box>
                        )}
                        <Box className="radioBox">
                          <Box row aria-label="position" name="position" defaultValue="top"
                            orientation="vertical"
                            value={fareValues}
                            onChange={handleChangeFares}
                          >
                          <Row>
                            <Col md={8} xs={8} className="servicesBoxs d-flex">
                              <Box className='pricewidth'>
                                <Box className="highlight-price"><span>	&#x20B9; </span>
                                {Math.ceil(props.results.slice(1)[index].FareDetails.b2b_PriceDetails._CustomerBuying)}
                                &nbsp;<Tooltip onClick={()=>viewDetailToggle(props.results.slice(1)[index], "2")} title="Fare Rules" arrow placement="top" ><InfoOutlinedIcon className='fare-rule-icon' /></Tooltip>
                                </Box>
                                {/* <Box className="itemssecttag">{value.Attr.Faretype}</Box> */}
                                <Box className="itemssecttag-supplier">{value.supplierName}</Box>

                              </Box>
                              {/* {props.showNetFare && (
                              <Box className='pricewidth'>
                                  <HtmlTooltip
                                    title={
                                      <Box>
                                        Commision: {value.FareDetails.b2b_PriceDetails._Commission} <br />
                                        Markup: {value.FareDetails.b2b_PriceDetails._Markup}
                                      </Box>
                                    } arrow placement="right">
                                    <Box className="shownet"><span>	&#x20B9; </span> {Math.ceil(value.FareDetails.b2b_PriceDetails._AgentBuying)}</Box>
                                  </HtmlTooltip>
                              </Box>
                               )} */}
                            </Col>  
                            {/* <Col md={4} xs={4} className="servicesBoxs"> 
                              <Box className='listiconbox'>
                                <ul className="listicon">

                                  <li className="listitemsicon">
                                    <HtmlTooltip title={
                                      <React.Fragment>
                                        Check-In Baggage {value.SegmentDetails[0][0].Baggage} <br />
                                        Cabin Baggage {value.SegmentDetails[0][0].CabinBaggage}
                                      </React.Fragment>
                                    } arrow placement="top">
                                      <img src={luggage}></img>
                                    </HtmlTooltip>
                                  </li>

                                  {value.Attr.IsRefundable
                                    ? (
                                      <li className="listitemsicon"><Tooltip title="Refundable" arrow placement="top"><img src={refund}></img></Tooltip></li>
                                    )
                                    : (
                                      <li className="listitemsicon"><Tooltip title="Non-Refundable" arrow placement="top"><img src={nonrefund}></img></Tooltip></li>
                                    )}
                                  <li className="listitemsicon"><Tooltip title={`Available seats ${value.SegmentDetails[0][0].AvailableSeats}`} arrow placement="top"><img src={seat}></img></Tooltip></li>
                                </ul>
                              </Box>

                            
                            </Col> */}
                          </Row>
                          
                              
                          </Box>
                        </Box>
                      </Col>
                      <Col xs={3} sm={3} md={2} lg={2} xl={2}>
                        <Button className="btnBookOutline" variant="contained"
                          onClick={() => flightBooking(fareDetails, Number(index+1))}
                        >Select
                        </Button>

                        
                      </Col>
                    </Row>
                    </li>
                  ))}

                </ul>
                </Box>


              )}

            </Col>
          </Row>
        </Box>

      </Box>
    </Box>
  )
}

export default OneWayResults
