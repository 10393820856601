import React, { useEffect, useState } from 'react'
import {Tooltip, Accordion, AccordionSummary, Popover,  Box, Button} from '@mui/material'
import { Col, Container, Row , } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { getPackageDetails } from './IndexApi';
// import ReactHtmlParser from 'react-html-parser'
import FlightIcon from '@mui/icons-material/Flight';
import FlightModal from './FlightModal';
import FlightOverview from './FlightOverview';

const AddFlight = (props) => {
    // const history = useHistory()
    const currentURL = window.location.href;
    var package_id = currentURL.split("?")[1];

    const [value, setValue] = useState(0)
    const [advanceShow, setAdvanceShow] = useState(false)
    const [open, setOpen] = useState(false);
    const [allInitialEvents, setAllInitialEvents] = useState([]);
    const [packageDetails, setPackageDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [adultQuantity, setAdultQuantity] = useState(1);
    const [childQuantity, setChildQuantity] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [showMore, setShowMore] = useState(true);
    const [modal, setModal] = useState(false);
    const [customModal, setCustomModal] = useState(false);
    const [expanded, setExpanded] = useState('panel1');
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [travelDate, setTravelDate] = useState(new Date());
    const [searchText, setSearchText] = useState("");
    const [searchTextGolf, setSearchTextGolf] = useState("");
    const [modalFlight, setModalFlight] = useState(false);
    const [onewayFlight, setOnewayFlight] = useState(null);
    const [onwardFlight, setOnwardFlight] = useState(null);
    const [returnFlight, setReturnFlight] = useState(null);
    const [flightsCost, setFlightsCost]= useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchObj, setSearchObj] = useState({});
    const [isInt, setIsInt] = useState(false);
    const [isInfoShow, setIsInfoShow] = useState(false);
    const [customized, setCustomized] = useState({
        'option_one': null, 'option_two': null,
        'option_three': null, 'option_four': [],
        'option_five': null, 'option_six': [],
        'option_seven': null
    })


    const handleModalFlightOpen = () => setModalFlight(true);
    const handleModalFlightClose = () => setModalFlight(false);

    

    const handleClickPopOver = (event) => {
      setAnchorEl(event.currentTarget);
      setIsInfoShow(false);
    };

    const handleClickPopOverShow = (event) => {
        setAnchorEl(event.currentTarget);
        setIsInfoShow(true);
      };

    const handleRemove = (e) => {
        setAnchorEl(null);
        setOnewayFlight(null);
        setOnwardFlight(null);
        setReturnFlight(null);
        setIsInt(false);
    }
  
    const handleClosePopOver = () => {
      setAnchorEl(null);
    };
  
    const openPopOver = Boolean(anchorEl);
    const id = openPopOver ? 'simple-popover' : undefined;

// console.log("onwardFlight", onwardFlight)  
// console.log("onewayFlight", onewayFlight)    
    useEffect(() => {
        if(props.onewayFlight !== null){
            setOnewayFlight(props.onewayFlight);
        }
        else if(props.onwardFlight !== null && props.returnFlight !== null){
            setOnwardFlight(props.onwardFlight);
            setReturnFlight(props.returnFlight); 
        }
    }, [props.onewayFlight, props.onwardFlight, props.returnFlight])

    useEffect(() => {
        loadTotalPrice()
    }, [adultQuantity])

    useEffect(() => {
        if (onewayFlight !== null) {
            setFlightsCost(Math.ceil(onewayFlight?.FareDetails?.b2b_PriceDetails?._CustomerBuying));
        }
        else if (onwardFlight !== null && returnFlight !== null && !isInt) {
            setFlightsCost(Math.ceil(onwardFlight?.FareDetails?.b2b_PriceDetails?._CustomerBuying + returnFlight?.FareDetails?.b2b_PriceDetails?._CustomerBuying))
        }
        else if (onwardFlight !== null && returnFlight !== null && isInt) {
            setFlightsCost(Math.ceil(onwardFlight?.FareDetails?.b2b_PriceDetails?._CustomerBuying))
        }
        else{
            setFlightsCost(0);
        }
    }, [onewayFlight, onwardFlight, returnFlight])

    useEffect(() => {
        setAdultQuantity(searchObj?.adult ? searchObj?.adult : 1);
        setChildQuantity(searchObj?.child ? searchObj?.child : 0)
    }, [searchObj])

    useEffect(() => {
        props.setOnewayFlight(onewayFlight);
        props.setOnwardFlight(onwardFlight);
        props.setReturnFlight(returnFlight);
    }, [onewayFlight, onwardFlight, returnFlight])

    // const loadActivityDetails = async () => {
    //     setIsLoading(true);
    //     try {
    //         await getPackageDetails(package_id)
    //             .then((response) => {
    //                 let data = response.data.data
    //                 // console.log("data", data)
    //                 setPackageDetails(data);
    //                 setAllInitialEvents(data);
    //                 setTotalPrice(Number(data?.package?.price.replace(",","")) * 1)
    //                 setIsLoading(false);
    //             })
    //             .catch((error) => {
    //                 console.log("Error", error);
    //                 setIsLoading(false);
    //             })
    //     } catch (error) {
    //         setIsLoading(false);
    //     }
    // }


    const handleModalOpen = () => setModal(true);
    const handleModalClose = () => setModal(false);

    const handleCustomModalOpen = () => setCustomModal(true);
    const handleCustomModalClose = () => setCustomModal(false);

    const advanceToggle = () => {
        setAdvanceShow(!advanceShow)
    }

    const handleTabinChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }



    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const MuiAccordionSummaryFAQ = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({

        flexDirection: 'row-reverse',
        '& .faq-section-aacordian .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .faq-section-aacordian .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));


    const loadTotalPrice = () => {
        setTotalPrice(Number(packageDetails?.package?.price.replace(",","")) * adultQuantity)
    }

    const onEditFlightPress = () => {
        setAnchorEl(null);
        setOnewayFlight(null);
        setOnwardFlight(null);
        setReturnFlight(null);
        setIsInt(false);
        setModalFlight(true);
    }

    function formatDate(date) {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    let newDate = `${mo}/${da}/${ye}`
    return newDate;
    }
    return <>
       
        <Container className='selectactivity-container margin'>
            
            <Box className='flight-added-wrp mb-3'>
                
                <Box className='mt-1'>
                    {(onewayFlight === null && onwardFlight === null && returnFlight === null) ?
                    (
                    <Row className='add-flight-btn'>
                        <p className='package-ttl-1 mt-3 text-black'><span><FlightIcon  />Add Flight</span> </p>
                        <Col xs={6} md={4} ><p className='text-medium mt-2'>No Flight Included -</p></Col>
                        <Col xs={6} md={6} className="text"> <Button variant='contained' onClick={handleModalFlightOpen}>+ Add Flight</Button></Col>
                    </Row>
                    ):(

                    <Row className='view-flight-btn'>
                        <Col xs={12} md={12} className="text-right"> 
                            <Button className='btn-error mx-2'  aria-describedby={id} onClick={handleRemove}> Remove</Button>
                        </Col> 
                       <Col xs={8} md={12} >
                            <Box>
                                <FlightOverview 
                                        onewayFlight={onewayFlight}
                                        onwardFlight={onwardFlight}
                                        returnFlight={returnFlight}
                                        searchObj={searchObj}
                                        isInfoShow={isInfoShow}
                                        onEditFlightPress={onEditFlightPress}
                                        selectedTravelers={props.selectedTravelers}
                                    />
                            </Box>
                        </Col>
                       
                    </Row>
                    )}
                    
                </Box>
            </Box>
    </Container>


        <Modal className='flight-modal' show={modalFlight} onHide={handleModalFlightClose}>
            <Modal.Header>
              <b>Select Flight</b>
              <Button className='float-right text-danger' onClick={handleModalFlightClose}>X</Button>
            </Modal.Header>
            <Modal.Body>
               <FlightModal 
                packageDetails={packageDetails} 
                setModalFlight={setModalFlight}
                setOnewayFlight={setOnewayFlight}
                setOnwardFlight={setOnwardFlight}
                setReturnFlight={setReturnFlight}
                searchObj={searchObj}
                setSearchObj={setSearchObj}
                setIsInt={setIsInt}
               />
            </Modal.Body>
            {/* <Modal.Footer>
            <Button className='btn btn-primary btn-small ml-2' color='success' variant='contained' onClick={handleModalFlightClose}>Update</Button> 
            <Button className='btn btn-danger' color='error' variant='contained' onClick={handleModalFlightClose}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    </>
}

export default AddFlight
