import React, { useEffect, useState } from 'react'
import { Box, Slider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import '../filters/filterstyle.css'
import { useDispatch, useSelector } from 'react-redux'
import { priceRange } from '../../../../store/flights/actions/flightFilterActions'

const useStyles = makeStyles({
  priceslider: {
    width: '100%',
    borderBottom: '1px solid #ccc',
    paddingBottom: '10px',
    paddingTop: '10px',
  }
})
//   function valuetext(value) {

//   }
const Pricerange = (props) => {
  const { handleRangChange, onPrice } = props

  const classes = useStyles()
  const dispatch = useDispatch()
  const range = useSelector(state => state.rangeValue)
  const [value, setValue] = useState([range.minValue, range.maxValue])

  const handleChange = (event, newValue) => {
    handleRangChange(newValue)
    setValue(newValue)
    dispatch(priceRange(newValue))
  }

  useEffect(() => {
    setValue([range.minValue, range.maxValue])
    onPrice([range.minValue, range.maxValue])
  }, [range])

  return (
        <div className={classes.priceslider}>
         <Box className="tlt-range">
             <div className="tlt-heading">Price</div>
         </Box>
            <Slider
            className="sliderrange"
            value={value}
            onChangeCommitted={handleChange}
            defaultValue={range.maxValue}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            // getAriaValueText={valuetext}
            max={range.maxValue}
            min={range.minValue}
            />
            <Box className="priceratelabel">
                <Box className="minprice"><span>&#x20B9; </span><span>{Math.round(value[0])}</span></Box>
                <Box className="maxprice"><span>&#x20B9; </span><span>{Math.round(value[1])}</span></Box>
            </Box>
        </div>
  )
}

export default Pricerange
