import React from 'react'
import './loader.css'
import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from '@mui/material/LinearProgress'
import airplane from '../../assets/images/airplane.png'
const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: '0px 0 10px',
    position: 'relative'
  }
})
const FlightLoader = () => {
  const classes = useStyles()
  const [progress, setProgress] = React.useState(0)
  const [text, setText] = React.useState('Searching for flights...')

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 90) {
          // return 0;
          clearInterval(timer)
        }

        if (oldProgress > 50) {
          // return 0;
          setText('Fetching flight details...')
        }

        const diff = Math.random() * 10
        return Math.min(oldProgress + diff, 90)
      })
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
      <div className={classes.root} >
          <div class="cloud cloud1">
            <img  src={airplane} />
          </div>
          <div className='progess-wrp flight-progress-wrp'>
            <span className='dashed-line'> </span>
            <LinearProgress variant="determinate" color='secondary' value={progress} />
            <span className='dashed-line'> </span>
          </div>
          <div className="texthighlited">{text}</div>
          
      </div>
  )
}

export default FlightLoader
