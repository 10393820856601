import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Box, Container, TextField, Select, MenuItem, FormControl, Button, LinearProgress, Pagination, Stack } from '@mui/material'
import { API_BASE_URL } from '../../utility/apiConstants'
import SideNav from '../../SideNav'
import MainFooter from '../footer/MainFooter'
import { countrylist } from '../../services/countryList';
import { authPost, getAuth, getReq } from '../../utility/apiConfig'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link, useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const UserApprovalList = () => {
    const resultPerPage = 20;
    const navigate = useNavigate();
    const [itemStatus, setItemStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [toggle, setToggle] = useState(false);
    const [userList, setUserList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event, user_id, user_status) => {
        setAnchorEl(event.currentTarget);
        setSelectedUserId(user_id)
        setItemStates(user_status)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [userDetails, setUserDetails] = useState({
        firstName: "", lastName: "", email: "",
        countryCode: "", phone: "", designation: "",
        city: "", country: "", countryOrigin: "",
        status: "all"
    });
    const [userDetailsErr, setUserDetailsErr] = useState({
        firstName: null, lastName: null, email: null,
        countryCode: null, phone: null, designation: null,
        city: null, country: null, status: null
    });

    const handleChange = (e) => {
        // setAge(event.target.value);
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
        setUserDetailsErr({ ...userDetailsErr, [e.target.name]: null });
    }

    const handlePhoneChange = (e) => {
        setUserDetailsErr({ ...userDetailsErr, phone: null });
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setUserDetails({ ...userDetails, phone: onlyNums })
        } else if (onlyNums.length === 10) {
            const number = onlyNums;
            setUserDetails({ ...userDetails, phone: number })
        }
    }

    useEffect(() => {
        let scripts = 'https://unpkg.com/libphonenumber-js/bundle/libphonenumber-js.min.js'
        var script = document.createElement("script");
        script.src = scripts
        script.async = true;
        document.body.appendChild(script);
    }, [])

    useEffect(() => {
        getUserList();
        let user_data = localStorage.getItem('user_data');
        user_data = JSON.parse(user_data);
        // setUserDetails({
        //     ...userDetails,
        //     countryCode: user_data.country_code_value,
        //     city: user_data.city,
        //     country: user_data.country,
        //     countryOrigin: user_data.country_code
        // })
        //setCurrentUser( user_data);
        // getStateList();
        // getCityList(user_data.country_code);
    }, [])

    const getUserList = (pageNo) => {
        const { firstName, lastName, email, countryCode, phone, designation, city, country, countryOrigin } = userDetails;
        let pageCount = (pageNo !== undefined && pageNo !== null) ? Number((pageNo - 1) * resultPerPage) : 0;
        let activePageIndex = (pageNo !== undefined && pageNo !== null) ? pageNo : 1;
        let status = userDetails.status === "all" ? "" : userDetails.status
        setIsLoading(true);
        try {
            getAuth(API_BASE_URL + `user/list_of_users/${pageCount}?first_name=${firstName}&email=${email}&phone=${phone}&country_code=${countryOrigin}&city=${city}&designation=${designation}&status=${status}&last_name=${lastName}&user_id=`)
                .then((response) => {
                    if (response.data && response.data.data && response.status) {
                        setUserList(response.data.data.data);
                        let total_records = response.data.data.total_records;
                        setTotalRecords(Math.ceil(JSON.parse(total_records) / resultPerPage));
                        setCurrentPage(activePageIndex);
                        setIsLoading(false);
                    }
                    else {
                        setIsLoading(false);
                        setCurrentPage(1);
                        setUserList([]);
                    }
                }).catch((error) => {
                    alert(error);
                    setIsLoading(false);
                    setCurrentPage(1);
                    setUserList([]);
                })
        } catch (error) {
            setIsLoading(false);
            setCurrentPage(1);
        }
    }

    const handleStatusUpdate = (status, userId) => {
        let dataToSend = {
            user_id: userId,
            status: status
        }
        if (window.confirm(`Are you sure to ${status === 1 ? "activate" : "deactivate"} the user?`)) {
            try {
                authPost(API_BASE_URL + 'user/change_user_status', dataToSend)
                    .then((response) => {
                        if (response.data.status) {
                            alert(response.data.message)
                            setIsLoading(false);
                            window.location.reload();
                        } else {
                            alert(response.data.message)
                            setIsLoading(false);
                        }
                    })
                    .catch((error) => {
                        alert('Somthing went wrong please try again')
                        setIsLoading(false);
                    })
            } catch (error) {

            }
        } else {
            // alert('No');
        }
    }

    const getCityList = (countryId) => {
        try {
            getReq(API_BASE_URL + 'data/get_cities/' + countryId, null)
                .then((response) => {
                    setCities(response.data.data.data);
                }).catch((error) => {
                    setCities([])
                })
        } catch (error) {

        }
    }
    const handleClear = () => {
        setUserDetails({
            firstName: "", lastName: "", email: "",
            countryCode: "", phone: "", designation: "",
            city: "", country: "", countryOrigin: "",
            status: "all"
        })
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <SideNav header={"Approval Requests"} />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Container>
                        <Card className='px-4 py-2 mt-3'>
                            <Row className='mt-3'>
                                <Col xs={12} sm={12} md={8} className='mb-1'>
                                    <h6 className='heading-1'>Users List</h6>
                                </Col>
                                <Col xs={12} sm={12} md={4} className='mb-1 d-flex justify-content-end'>
                                    <Button variant='contained' color='primary' size='small' className="mx-3" onClick={() => { setToggle(!toggle) }}><SearchIcon style={{ fontSize: 16 }} />&nbsp;Search</Button>
                                    {/* <Link to="/hotel-inventory"><Button variant='contained' color='success' size='small' ><AddCircleOutlineIcon style={{fontSize: 16}}  />&nbsp;Add Hotel Inventory</Button></Link> */}
                                </Col>
                            </Row>
                            {toggle &&
                                <Card className='p-3 mt-3'>
                                    <Row>
                                        <Col xs={12} sm={12} md={3} >
                                            <Box className="setBox ">
                                                <div className="tlthedae">First name</div>
                                                <TextField
                                                    autoComplete="given-name"
                                                    name="firstName"
                                                    required
                                                    fullWidth
                                                    id="firstName"
                                                    placeholder="First name"
                                                    // autoFocus
                                                    value={userDetails.firstName}
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                        </Col>
                                        <Col xs={12} sm={12} md={3} >
                                            <Box className="setBox ">
                                                <div className="tlthedae">Last name</div>
                                                <TextField
                                                    autoComplete="given-name"
                                                    name="lastName"
                                                    required
                                                    fullWidth
                                                    id="lastName"
                                                    placeholder="Last name"
                                                    // autoFocus
                                                    value={userDetails.lastName}
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                        </Col>
                                        <Col xs={12} sm={12} md={3}>
                                            <Box className="setBox">
                                                <div className="tlthedae">Email ID</div>
                                                <TextField
                                                    autoComplete="given-name"
                                                    name="email"
                                                    required
                                                    fullWidth
                                                    id="email"
                                                    placeholder="Email ID"
                                                    // autoFocus
                                                    value={userDetails.email}
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                        </Col>

                                        <Col xs={12} sm={12} md={3}>
                                            <Box className="setBox">
                                                <div className="tlthedae">Phone Number</div>
                                                <TextField
                                                    autoComplete="given-name"
                                                    name="phone"
                                                    required
                                                    fullWidth
                                                    id="phone"
                                                    placeholder="Phone Number"
                                                    // autoFocus
                                                    value={userDetails.phone}
                                                    onChange={handlePhoneChange}
                                                />
                                            </Box>
                                        </Col>


                                        <Col xs={12} sm={12} md={3}>
                                            <Box className="setBox">
                                                <div className="tlthedae">Country</div>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={countrylist}
                                                        getOptionLabel={(option) => option.name}
                                                        value={countrylist.filter(x => x.name === userDetails.country)[0]}
                                                        renderInput={(params) => <TextField {...params} error={userDetailsErr.country} />}
                                                        onChange={(event, newValue) => {
                                                            if (newValue !== null) {
                                                                setUserDetails({ ...userDetails, country: newValue.name, countryCode: newValue.country_code, countryOrigin: newValue.origin });
                                                                setUserDetailsErr({ ...userDetailsErr, country: null, countryCode: null });
                                                                getCityList(newValue.origin);
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                        </Col>

                                        <Col xs={12} sm={12} md={3}>
                                            <Box className="setBox">
                                                <div className="tlthedae">City</div>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={cities}
                                                        getOptionLabel={(option) => option.destination}
                                                        value={cities.filter(x => x.origin === userDetails.city)[0]}
                                                        renderInput={(params) => <TextField {...params} error={userDetailsErr.city} />}
                                                        onChange={(event, newValue) => {
                                                            if (newValue !== null) {
                                                                setUserDetails({ ...userDetails, city: newValue.origin });
                                                                setUserDetailsErr({ ...userDetailsErr, city: null });
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                        </Col>
                                        <Col xs={12} sm={12} md={3}>
                                            <Box className="setBox">
                                                <div className="tlthedae">Designation</div>
                                                <TextField
                                                    autoComplete="given-name"
                                                    name="designation"
                                                    required
                                                    fullWidth
                                                    id="designation"
                                                    placeholder="Designation"
                                                    // autoFocus
                                                    value={userDetails.designation}
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                        </Col>

                                        <Col xs={12} sm={12} md={3}>
                                            <Box className="setBox">
                                                <div className="tlthedae">Status</div>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={userDetails.status}
                                                        placeholder="Status"
                                                        name="status"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem key={1} value={"all"}>All</MenuItem>
                                                        <MenuItem key={2} value={'true'}>Active</MenuItem>
                                                        <MenuItem key={3} value={'false'}>Inactive</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Col>
                                        <Col xs={12} sm={12} md={3} className='mt-1'>
                                            <Button variant='contained' size="small" color='success' className='mb-3 mt-3' onClick={() => getUserList()}>Search</Button>
                                            <Button variant='contained' size="small" color='warning' className='mb-3 mt-3 mx-2' onClick={() => handleClear()}>Clear</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            }
                            <Row className='my-3'>
                                {/* <Col xs={12} sm={12} md={12} className='mb-2'>
                                    <h6 className='heading-1'>Hotel Inventory List</h6>
                                </Col> */}
                                <Col xs={12} md={12}>
                                    <TableContainer >
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Name</TableCell>
                                                    <TableCell align="left">Email ID</TableCell>
                                                    <TableCell align="left">Phone Number</TableCell>
                                                    <TableCell align="left">Country</TableCell>
                                                    <TableCell align="left">City</TableCell>
                                                    <TableCell align="left">Designation</TableCell>
                                                    <TableCell align="left">Status</TableCell>
                                                    <TableCell align="left">Special Status</TableCell>
                                                    <TableCell align="right">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(userList.length && !isLoading) > 0 ? (
                                                    <>
                                                        {userList?.map((item) => (
                                                            <TableRow
                                                                key={item.user_id}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell align="left" className='text-capitalize'>{item.title + " " + item.first_name + " " + item.middle_name + " " + item.last_name}</TableCell>
                                                                <TableCell align="left">{item.email}</TableCell>
                                                                <TableCell align="left">{item.phone}</TableCell>
                                                                <TableCell align="left" className='text-capitalize'>{item.country}</TableCell>
                                                                <TableCell align="left" className='text-capitalize'>{item.city_name}</TableCell>
                                                                <TableCell align="left" className='text-capitalize'>{item.designation}</TableCell>
                                                                <TableCell align="left">
                                                                    {item.status === "1" ? (
                                                                        <span className='badge badge-success'>Active</span>
                                                                    ) : (
                                                                        <span className='badge badge-danger'>Inactive</span>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {(item?.special_status === "vvip" || item?.special_status === "vip") ? (
                                                                        <div id="badge">
                                                                            <a><span className='text-uppercase'>{item?.special_status}</span></a>
                                                                        </div>
                                                                    ) : item?.special_status === "handle_with_care" ? (
                                                                        <div className="">
                                                                            <a><span>Handle With Care</span></a>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="">
                                                                            <a><span>Regular</span></a>
                                                                        </div>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <IconButton
                                                                        className='menu-dropdown'
                                                                        aria-label="more"
                                                                        id="long-button"
                                                                        aria-controls={open ? 'long-menu' : undefined}
                                                                        aria-expanded={open ? 'true' : undefined}
                                                                        aria-haspopup="true"
                                                                        onClick={(e) => handleClick(e, item.user_id, item.status)}
                                                                    >
                                                                        <MoreVertIcon />
                                                                    </IconButton>
                                                                    <Menu
                                                                        id="long-menu"
                                                                        MenuListProps={{
                                                                            'aria-labelledby': 'long-button',
                                                                        }}
                                                                        anchorEl={anchorEl}
                                                                        open={open}
                                                                        onClose={handleClose}
                                                                    >
                                                                        <Link to={"/edit-profile?" + selectedUserId} ><MenuItem>Edit</MenuItem></Link>
                                                                        {itemStatus === "1" ? (
                                                                            <MenuItem onClick={() => handleStatusUpdate(0, selectedUserId)} color='error' >Deactivate</MenuItem>
                                                                        ) : (
                                                                            <MenuItem onClick={() => handleStatusUpdate(1, selectedUserId)} color='success' >Activate</MenuItem>
                                                                        )}
                                                                    </Menu>
                                                                </TableCell>
                                                                {/* <TableCell align="right">
                                                    <Button onClick={()=> navigate("/edit-profile?" + item.user_id)} color='error' >Edit</Button>
                                                    {item.status === "1" ? (
                                                    <Button onClick={()=> handleStatusUpdate(0, item.user_id)} color='error' >Deactivate</Button>
                                                    ) : (
                                                    <Button onClick={()=> handleStatusUpdate(1, item.user_id)} color='success' >Activate</Button>
                                                    )}
                                                </TableCell> */}
                                                            </TableRow>
                                                        ))}
                                                    </>
                                                ) : (!isLoading) ? (
                                                    <TableRow>
                                                        <TableCell align="left" colspan="6"> <p><b>No data found...</b></p></TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <TableRow>
                                                        <TableCell align="left" colspan="6">
                                                            <LinearProgress color="success" />
                                                        </TableCell>
                                                    </TableRow>

                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {totalRecords > 1 && (
                                        <Box className="my-2 px-2 float-right">
                                            <Stack spacing={2}>
                                                <Pagination page={currentPage} count={totalRecords} onChange={(e, index) => getUserList(index)} variant="outlined" color="primary" />
                                            </Stack>
                                        </Box>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Box>
            </Box>
            <MainFooter />
        </>
    )
}

export default UserApprovalList
