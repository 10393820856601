import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import arrowFlight from '../../../assets/images/arrowflight.png';
import { Tabs, Tab } from '@mui/material'
import { Typography, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import { Tooltip } from '@mui/material'
import './flights.css'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from 'moment';
import { stopsCount } from '../../../core/airlineStops';
// import ReactHtmlParser from 'react-html-parser';
import { API_BASE_URL } from '../../../utility/apiConstants';
import { authPost } from '../../../utility/apiConfig';
import arrowimg from '../../../assets/images/arrow.svg'
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import { formatDate, formatDate1, toDigit } from '../../../core/helper';
import { fareRulsDetails } from '../../../services/fareDetails';
import { useNavigate } from 'react-router-dom';

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
}

function viewOnewayDetailsToggle(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    }
}

function viewDetailsToggle(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    }
}

function viewReturnDetailsToggle(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    }
}


const FlightOverview = (props) => {
    const history = useNavigate()
    const { fsRoundReqData, fsRequestData } = useSelector(state => state.flightResult)
    const { onewayFlight, onwardFlight, returnFlight, searchObj } = props;
    const [toggle, setToggle] = useState(false);
    const [returnToggle, setReturnToggle] = useState(false);
    const [onewayToggle, setOnewayToggle] = useState(false)
    const [value, setValue] = useState(0);
    const [fareValues, setFareValues] = useState('0');
    const [fareDetails, setFareDetails] = useState({});
    const [fareRules, setFareRules] = useState(null);
    const [fareLoader, setFareLoader] = useState(false);
    const [paxCount, setPaxCount] = useState(fsRoundReqData?.adult + fsRoundReqData?.child);
    const [onewayPaxCount, setOnewayPaxCount] = useState(fsRequestData?.adult + fsRequestData?.child);
    const [toggleMoreFlight, setToggleMoreFlight] = useState(false);
    let totalPaxCount = props?.selectedTravelers?.length !== 0 ? 
                        props?.selectedTravelers.length : 
                        searchObj.infant + searchObj.child + searchObj.adult;

    useEffect(() => {
        let flight_info = props.onewayFlight !== null ? props.onewayFlight : props.onwardFlight;
        if(props.isInfoShow && props.onewayFlight === null){
            viewDetailToggle(flight_info, "1")
            handleFlighttabsChange(null, 1);
        }
        else if(props.isInfoShow && props.onewayFlight !== null){
            viewOnewayDetailToggle(flight_info, "1")
            handleFlighttabsChange(null, 1);
        }
    }, [])
    
    const viewDetailToggle = (item, type) => {
        if (type === "1") {
            setToggle(true)
            // setFareDetails(props.results[fareValues])
            setValue(0)
            setToggleMoreFlight(false)
        }
        else if (type === "2") {
            setToggle(true)
            // setFareDetails(item)
            setValue(0)
            setToggleMoreFlight(false)
        }
        else {
            setToggle(false)
            setValue(0)
            setToggleMoreFlight(false)
        }
        setToggle(!toggle)
    }

    const viewReturnDetailToggle = (item, type) => {
        if (type === "1") {
            setReturnToggle(true)
            // setFareDetails(props.results[fareValues])
            setValue(0)
            setToggleMoreFlight(false)
        }
        else if (type === "2") {
            setReturnToggle(true)
            // setFareDetails(item)
            setValue(0)
            setToggleMoreFlight(false)
        }
        else {
            setReturnToggle(false)
            setValue(0)
            setToggleMoreFlight(false)
        }
        setReturnToggle(!returnToggle)
    }

    const viewOnewayDetailToggle = (item, type) => {
        if (type === "1") {
            setOnewayToggle(true)
            setValue(0)
        }
        else if (type === "2") {
            setOnewayToggle(true)
            setValue(0)
        }
        else {
            setOnewayToggle(false)
            setValue(0)
        }
        setOnewayToggle(!onewayToggle)
    }

    const handleIntFlighttabsChange = (event, newValue) => {
        setValue(newValue)
        let data = {...onwardFlight, 
            "SegmentDetails": [...onwardFlight.SegmentDetails, ...returnFlight.SegmentDetails],
            "SegmentSummary": [...onwardFlight.SegmentSummary, ...returnFlight.SegmentSummary]
        }
        //console.log("Data", data)
        if (newValue === 2) {
            getIntFareRules(data)
        }
    }

    const handleFlighttabsChange = (event, newValue) => {
        setValue(newValue)

        if (newValue === 2) {
            getFareRules(onwardFlight)
        }
    }

    const handleReturnFlighttabsChange = (event, newValue) => {
        setValue(newValue)

        if (newValue === 2) {
            getFareRules(returnFlight)
        }
    }

    const handleOnewayFlighttabsChange = (event, newValue) => {
        setValue(newValue)

        if (newValue === 2) {
            getFareRules(onewayFlight)
        }
    }

    const getFareRules = useCallback(
        (data) => {
            setFareLoader(true)

            const trt = data?.iti_origin

            const obj = {}
            if (trt !== undefined) {
                obj.data_access_key = data.Token
                obj.booking_source = data.booking_source
                obj.search_access_key = data.ProvabAuthKey
                obj.provab_auth_key = 'undefined'
                obj.iti_origin = trt
            } else {
                obj.data_access_key = data.Token
                obj.booking_source = data.booking_source
                obj.search_access_key = data.ProvabAuthKey
                obj.provab_auth_key = 'undefined'
            }
            try {
                authPost(API_BASE_URL + 'ajax/get_fare_details', obj)
                    .then((response) => {
                        setFareLoader(false)
                        if (response.data.status) {
                            const info = response.data.data[0]?.fare_rules
                            if (info !== undefined) {
                                const iic = info.concat(response.data.data[0]?.cancellation_policy)
                                setFareRules(iic)
                            } else {
                                setFareRules(response.data.data.data[0].FareRules)
                            }
                        } else {
                            setFareRules(response.data.data.msg)
                        }
                    }).catch((error) => {
                        console.log("Error", error)
                        setFareLoader(false)
                        setFareRules('Somthing went wrong please try again...')
                    })
            } catch (error) {
                setFareLoader(false)
                setFareRules('Somthing went wrong please try again...')
            }
        }
    )

    const getIntFareRules = useCallback(
        (data) => {
          setFareLoader(true)
          const obj = {
            data_access_key: data.Token,
            booking_source: data.booking_source,
            search_access_key: data.ProvabAuthKey,
            provab_auth_key: 'undefined'
          }
          try {
            authPost(API_BASE_URL + 'ajax/get_fare_details', obj)
              .then((response) => {
                setFareLoader(false)
                if (response.data.status) {
                  const info = response.data.data[0]?.fare_rules
                  if (info !== undefined) {
                    const iic = info.concat(response.data.data[0]?.cancellation_policy)
                    setFareRules(iic)
                  } else {
                    setFareRules(response.data.data.data[0].FareRules)
                  }
                } else {
                  setFareRules(response.data.data.msg)
                }
              }).catch((error) => {
                setFareRules("Fare details not available at this moment.")
              })
          } catch (error) {
            setFareLoader(false)
            setFareRules('Somthing went wrong please try again...')
          }
        }
      )


     // console.log("onwardFlight", onwardFlight, "returnFlight", returnFlight)
    return (
        <>
            {(onwardFlight !== null && returnFlight !== null && props.searchObj.fromCountry === "India" && props.searchObj.toCountry === "India") ? (
                <Box className='acti-flight-list card card-body'>
                    <Box className="bgflightdetailscard">
                        <Box className='flight-heading'>
                            <h1>Selected Onward Flight</h1>
                        </Box>
                        <Row className="viewresultbooking">
                            <Col xs={5} sm={5} md={8}>
                                <Row>
                                    <Col xs={5} sm={4} md={4} className="zerogutter m-auto">
                                        <Box className="flightimg">
                                            <Box className='imgminifly'>
                                            </Box>
                                            <Box className="departimgminifly">
                                                <img className={'itwgo-60x35_' + onwardFlight?.SegmentSummary[0]?.AirlineDetails?.AirlineCode} />
                                            </Box>
                                            <Box className="mini-tag">
                                                <Box className="flightname">{onwardFlight?.SegmentSummary[0]?.AirlineDetails?.AirlineName}</Box>
                                                <Box className="flightnumber">{onwardFlight?.SegmentSummary[0]?.AirlineDetails?.AirlineCode}-{onwardFlight?.SegmentSummary[0]?.AirlineDetails?.FlightNumber}</Box>
                                            </Box>
                                        </Box>

                                    </Col>
                                    <Col xs={7} sm={8} md={8} className="pl-xs-0">
                                        <Box className="flexset">
                                            <Box className="definewidth">
                                                <Box className="flightmininame">{onwardFlight?.SegmentSummary[0]?.OriginDetails?.CityName}</Box>
                                                <Box className="flightminitime">{moment(onwardFlight?.SegmentSummary[0]?.OriginDetails?.DateTime).format('hh:mm')}</Box>
                                                <Box className="flightminidata">{moment(onwardFlight?.SegmentSummary[0]?.OriginDetails?.DateTime).format('Do MMM YYYY')}</Box>
                                            </Box>
                                            <Box className="arrowbox ">
                                                <Box className="define-stop">{stopsCount[onwardFlight?.SegmentSummary[0]?.TotalStops]}</Box>
                                                <Box className="arrow-img"> <span className='arrow-line'></span><img src={arrowFlight}></img></Box>
                                                <Box className="define-timecalc">{onwardFlight?.SegmentSummary[0]?.TotalDuaration}</Box>
                                            </Box>
                                            <Box className="definewidth">
                                                <Box className="flightmininame">{onwardFlight?.SegmentSummary[0]?.DestinationDetails?.CityName}</Box>
                                                <Box className="flightminitime">{moment(onwardFlight?.SegmentSummary[0]?.DestinationDetails?.DateTime).format('hh:mm')}</Box>
                                                <Box className="flightminidata">{moment(onwardFlight?.SegmentSummary[0]?.DestinationDetails?.DateTime).format('Do MMM YYYY')}</Box>
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>
                            </Col>
                            
                            <Col xs={3} md={2} className="zerogap m-auto">
                                <Box className="radioBox">
                                    <Box row aria-label="position" name="position" defaultValue="top"
                                        orientation="vertical"
                                    // value={fareValues}
                                    // onChange={handleChangeFares}
                                    >
                                        <Box className="servicesBox">
                                            <Row>
                                                <Col md={8} xs={8} className="servicesBoxs d-flex">
                                                    <Box className='pricewidth'>
                                                        <Box className="highlight-price"><span>	&#x20B9; </span> {onwardFlight?.FareDetails?.b2b_PriceDetails?._CustomerBuying}
                                                            &nbsp;<Tooltip onClick={() => viewDetailToggle(onwardFlight, "1")}
                                                                title="Fare Rules" arrow placement="top" ><InfoOutlinedIcon className='fare-rule-icon' /></Tooltip>
                                                        </Box>
                                                        {/* <Box className="itemssecttag">{onwardFlight?.Attr?.Faretype}</Box> */}
                                                    </Box>
                                                </Col>
                                            </Row>
                                        </Box>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={3} md={2} className="m-auto">
                                <Button className="btnBook" variant="contained"
                                onClick={() => props.onEditFlightPress()}
                                >Edit</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="zerogap">

                                {toggle && (
                                    <Box className="tabsBox">
                                        <Tabs className="flighttabsdetails" value={value} onChange={handleFlighttabsChange} aria-label="simple tabs example">
                                            <Tab className="flighttabsdetails-link" label="flight details" {...viewDetailsToggle(0)} />
                                            <Tab className="flighttabsdetails-link" label="fare details" {...viewDetailsToggle(1)} />
                                            <Tab className="flighttabsdetails-link" label="Fare Rules" {...viewDetailsToggle(2)} />
                                            <Tab className="flighttabsdetails-link" label="baggage information" {...viewDetailsToggle(3)} />
                                        </Tabs>
                                        {/* <Box className="tltfaretype-desc">Fare Type:
                                            <span className="tltBaggage-sm"> &nbsp;{onwardFlight?.Attr.Faretype}</span>
                                        </Box> */}
                                        <TabPanel className="tabpanelsflightpanel" value={value} index={0}>

                                            {onwardFlight?.SegmentDetails[0].map((value, index) => (

                                                <Box className="tabcontentsbox">
                                                    <Box className="to-fromtlt">
                                                        <span className="fromname">{value.OriginDetails.CityName} </span>
                                                        <span className="icoarrowfromto">
                                                            <img src={arrowimg}></img>
                                                        </span>
                                                        <span className="toname">{value.DestinationDetails.CityName}</span>
                                                        <span className="dateformto">{formatDate(value.OriginDetails.DateTime)}</span>
                                                    </Box>
                                                    <Row>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                            <Box className="flightimg">
                                                                <Box className="imgminifly">
                                                                    <img className={'itwgo-60x35_' + value.AirlineDetails.AirlineCode} />
                                                                </Box>
                                                                <Box className="mini-tag">
                                                                    <Box className="flightname">{value.AirlineDetails.AirlineName}</Box>
                                                                    <Box className="flightnumber">{value.AirlineDetails.AirlineCode}-{value.AirlineDetails.FlightNumber}</Box>
                                                                </Box>
                                                            </Box>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Row>
                                                                <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                                                    <Box className="flightairportaddress">
                                                                        <div className="tltdate">{formatDate1(value.OriginDetails.DateTime)}</div>
                                                                        <p className='mb-0'>{value.OriginDetails.AirportName}, {value.OriginDetails.CityName}</p>
                                                                        {value?.OriginDetails?.Terminal ? <p>Terminal {value.OriginDetails.Terminal}</p> : null}
                                                                    </Box>
                                                                </Col>
                                                                <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap m-inherit">
                                                                    <Box className="arrowtabtxt">Non-Stop</Box>
                                                                    <Box className="arrowtabinfo"><img src={arrowimg}></img></Box>
                                                                    <Box className="define-timeinfo">{value.SegmentDuration}</Box>
                                                                </Col>
                                                                <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                                                    <Box className="flightairportaddress">
                                                                        <div className="tltdate">{formatDate1(value.DestinationDetails.DateTime)}</div>
                                                                        <p className='mb-0'>{value.DestinationDetails.AirportName}, {value.DestinationDetails.CityName}</p>
                                                                        {value?.DestinationDetails?.Terminal ? <p>Terminal {value.DestinationDetails.Terminal}</p> : null}

                                                                    </Box>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                            <Box className="time-calc">{value.SegmentDuration}</Box>
                                                            <Box className="type">{fsRoundReqData?.v_class}</Box>
                                                            {/* <Box className="seathighighted"><span className="seathightlighted">{value.AvailableSeats} seats left</span> </Box> */}
                                                        </Col>
                                                    </Row>
                                                    <Box className="layoverbox">
                                                        {
                                                            index < onwardFlight?.SegmentDetails[0].length - 1
                                                                ? <Box className="layover">Layover <span>{value.WaitingTime}</span></Box>
                                                                : <Box></Box>
                                                        }
                                                    </Box>
                                                </Box>
                                            ))
                                            }

                                        </TabPanel>

                                        <TabPanel value={value} index={1}>
                                            <Row className="headerRow">
                                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <Box className="tltBaggage">Type</Box>
                                                </Col>
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                    <Box className="tltBaggage">Fare</Box>
                                                </Col>
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                    <Box className="tltBaggage">Total</Box>
                                                </Col>
                                            </Row>

                                            {!!fsRoundReqData?.adult && (
                                                <>
                                                    <Row>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                                                            <Box className="tltfaredetails"><span className="note-qt">Fare Details for Adult({onwardFlight?.SegmentSummary[0].AirlineDetails.FareClass}) ({onwardFlight?.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                                            <Box className="tltfaredetails">Base Price</Box>
                                                            <Box className="tltfaredetails">Taxes & fee</Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                            <Box className="tltfaredetails">&nbsp;</Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(onwardFlight?.PassengerFareBreakdown.ADT.BaseFare) / (onwardFlight?.PassengerFareBreakdown.ADT.PassengerCount)} X {onwardFlight?.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>

                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((onwardFlight?.PassengerFareBreakdown.ADT.Tax) / (onwardFlight?.PassengerFareBreakdown.ADT.PassengerCount) + (onwardFlight?.FareDetails.b2b_PriceDetails._Markup) / (paxCount))} X {onwardFlight?.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                            <Box className="tltfaredetails">&nbsp;</Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(onwardFlight?.PassengerFareBreakdown.ADT.BaseFare)}</span></Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((onwardFlight?.PassengerFareBreakdown.ADT.Tax) / (onwardFlight?.PassengerFareBreakdown.ADT.PassengerCount) + (onwardFlight?.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (onwardFlight?.PassengerFareBreakdown.ADT.PassengerCount)}</span></Box>
                                                        </Col>
                                                    </Row>
                                                    <Row className="totalRow">
                                                        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                                            <Box className="tlt-total">Total</Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                            <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(onwardFlight?.PassengerFareBreakdown.ADT.BaseFare + ((onwardFlight?.PassengerFareBreakdown.ADT.Tax) / (onwardFlight?.PassengerFareBreakdown.ADT.PassengerCount) + (onwardFlight?.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (onwardFlight?.PassengerFareBreakdown.ADT.PassengerCount))}</span></Box>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}

                                            {!!fsRoundReqData?.child && (
                                                <>
                                                    <Row>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Box className="tltfaredetails"><span className="note-qt">Fare Details for Child({onwardFlight?.SegmentSummary[0].AirlineDetails.FareClass}) ({onwardFlight?.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                                            <Box className="tltfaredetails">Base Price</Box>
                                                            <Box className="tltfaredetails">Taxes & fee</Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                            <Box className="tltfaredetails">&nbsp;</Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(onwardFlight?.PassengerFareBreakdown.CHD.BaseFare) / (onwardFlight?.PassengerFareBreakdown.CHD.PassengerCount)} X {onwardFlight?.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>

                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((onwardFlight?.PassengerFareBreakdown.CHD.Tax) / (onwardFlight?.PassengerFareBreakdown.CHD.PassengerCount) + (onwardFlight?.FareDetails.b2b_PriceDetails._Markup) / (paxCount))} X {onwardFlight?.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                            <Box className="tltfaredetails">&nbsp;</Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(onwardFlight?.PassengerFareBreakdown.CHD.BaseFare)}</span></Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((onwardFlight?.PassengerFareBreakdown.CHD.Tax) / (onwardFlight?.PassengerFareBreakdown.CHD.PassengerCount) + (onwardFlight?.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (onwardFlight?.PassengerFareBreakdown.CHD.PassengerCount)}</span></Box>
                                                        </Col>
                                                    </Row>
                                                    <Row className="totalRow">
                                                        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                                            <Box className="tlt-total">Total</Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                            <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(onwardFlight?.PassengerFareBreakdown.CHD.BaseFare + ((onwardFlight?.PassengerFareBreakdown.CHD.Tax) / (onwardFlight?.PassengerFareBreakdown.CHD.PassengerCount) + (onwardFlight?.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (onwardFlight?.PassengerFareBreakdown.CHD.PassengerCount))}</span></Box>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}

                                            {!!fsRoundReqData?.infant && (
                                                <>
                                                    <Row>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Box className="tltfaredetails"><span className="note-qt">Fare Details for Infant({onwardFlight?.SegmentSummary[0].AirlineDetails.FareClass}) ({onwardFlight?.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                                            <Box className="tltfaredetails">Base Price</Box>
                                                            <Box className="tltfaredetails">Taxes & fee</Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                            <Box className="tltfaredetails">&nbsp;</Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(onwardFlight?.PassengerFareBreakdown.INF.BaseFare) / (onwardFlight?.PassengerFareBreakdown.INF.PassengerCount)} X {onwardFlight?.PassengerFareBreakdown.INF.PassengerCount}</span></Box>

                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((onwardFlight?.PassengerFareBreakdown.INF.Tax) / (onwardFlight?.PassengerFareBreakdown.INF.PassengerCount))} X {onwardFlight?.PassengerFareBreakdown.INF.PassengerCount}</span></Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                            <Box className="tltfaredetails">&nbsp;</Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(onwardFlight?.PassengerFareBreakdown.INF.BaseFare)}</span></Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(Math.ceil(onwardFlight?.PassengerFareBreakdown.INF.Tax))}</span></Box>
                                                        </Col>
                                                    </Row>
                                                    <Row className="totalRow">
                                                        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                                            <Box className="tlt-total">Total</Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                            <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(onwardFlight?.PassengerFareBreakdown.INF.BaseFare + ((onwardFlight?.PassengerFareBreakdown.INF.Tax) / (onwardFlight?.PassengerFareBreakdown.INF.PassengerCount) + (onwardFlight?.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (onwardFlight?.PassengerFareBreakdown.INF.PassengerCount))}</span></Box>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}

                                        </TabPanel>

                                        <TabPanel value={value} index={2}>

                                            {fareLoader
                                                ? (
                                                    <span>Please wait...</span>
                                                )
                                                : (
                                                    <>
                                                        {/* <p className="p-font14">{ReactHtmlParser(fareRules)}</p> */}
                                                    </>
                                                )}

                                        </TabPanel>

                                        <TabPanel value={value} index={3}>
                                            <>
                                                <Row className="headerRow">
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                        <Box className="tltBaggage">Sector</Box>
                                                    </Col>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Box className="tltBaggage">Check-in</Box>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                        <Box className="tltBaggage">Cabin</Box>
                                                    </Col>
                                                </Row>

                                                {onwardFlight?.SegmentDetails[0].map((value, index) => (
                                                    <Row>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                            <Box className="tltBaggage-desc">{value.OriginDetails.AirportCode} - {value.DestinationDetails.AirportCode}</Box>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Box className="tltBaggage-desc">{value.Baggage}</Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                            <Box className="tltBaggage-desc">{value.CabinBaggage}</Box>
                                                        </Col>
                                                    </Row>
                                                ))}

                                            </>
                                        </TabPanel>



                                        <Box className="closebox" onClick={viewDetailToggle}><CloseIcon /></Box>
                                    </Box>

                                )}

                            </Col>
                        </Row>
                        <h6 className='ttl-pax'>Total Pax: {totalPaxCount}</h6>
                    </Box>

                    <Box className="bgflightdetailscard">
                        <Box className='flight-heading'>
                            <h1>Selected Return Flight</h1>
                        </Box>
                        <Row className="viewresultbooking">
                            <Col xs={5} sm={4} md={3} className="zerogutter m-auto">
                                <Box className="flightimg">
                                    <Box className='imgminifly'>
                                    </Box>
                                    <Box className="departimgminifly">
                                        <img className={'itwgo-60x35_' + returnFlight?.SegmentSummary[0]?.AirlineDetails?.AirlineCode} />
                                    </Box>
                                    <Box className="mini-tag">
                                        <Box className="flightname">{returnFlight?.SegmentSummary[0]?.AirlineDetails?.AirlineName}</Box>
                                        <Box className="flightnumber">{returnFlight?.SegmentSummary[0]?.AirlineDetails?.AirlineCode}-{returnFlight?.SegmentSummary[0]?.AirlineDetails?.FlightNumber}</Box>
                                    </Box>
                                </Box>

                            </Col>
                            <Col xs={7} sm={8} md={5} className="pl-xs-0">
                                <Box className="flexset">
                                    <Box className="definewidth">
                                        <Box className="flightmininame">{returnFlight?.SegmentSummary[0]?.OriginDetails?.CityName}</Box>
                                        <Box className="flightminitime">{moment(returnFlight?.SegmentSummary[0]?.OriginDetails?.DateTime).format('hh:mm')}</Box>
                                        <Box className="flightminidata">{moment(returnFlight?.SegmentSummary[0]?.OriginDetails?.DateTime).format('Do MMM YYYY')}</Box>
                                    </Box>
                                    <Box className="arrowbox ">
                                        <Box className="define-stop">{stopsCount[returnFlight?.SegmentSummary[0]?.TotalStops]}</Box>
                                        <Box className="arrow-img"> <span className='arrow-line'></span><img src={arrowFlight}></img></Box>
                                        <Box className="define-timecalc">{returnFlight?.SegmentSummary[0]?.TotalDuaration}</Box>
                                    </Box>
                                    <Box className="definewidth">
                                        <Box className="flightmininame">{returnFlight?.SegmentSummary[0]?.DestinationDetails?.CityName}</Box>
                                        <Box className="flightminitime">{moment(returnFlight?.SegmentSummary[0]?.DestinationDetails?.DateTime).format('hh:mm')}</Box>
                                        <Box className="flightminidata">{moment(returnFlight?.SegmentSummary[0]?.DestinationDetails?.DateTime).format('Do MMM YYYY')}</Box>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={9} md={2} className="zerogap m-auto">
                                <Box className="radioBox">
                                    <Box row aria-label="position" name="position" defaultValue="top"
                                        orientation="vertical"
                                    // value={fareValues}
                                    // onChange={handleChangeFares}
                                    >
                                        <Box className="servicesBox">
                                            <Row>
                                                <Col md={8} xs={8} className="servicesBoxs d-flex">
                                                    <Box className='pricewidth'>
                                                        <Box className="highlight-price"><span>	&#x20B9; </span> {returnFlight?.FareDetails?.b2b_PriceDetails?._CustomerBuying}
                                                            &nbsp;<Tooltip onClick={() => viewReturnDetailToggle(returnFlight, "2")}
                                                                title="Fare Rules" arrow placement="top" ><InfoOutlinedIcon className='fare-rule-icon' /></Tooltip>
                                                        </Box>
                                                        {/* <Box className="itemssecttag">{returnFlight?.Attr?.Faretype}</Box> */}
                                                    </Box>
                                                </Col>
                                            </Row>
                                        </Box>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={3} md={2} className="m-auto">
                                <Button className="btnBook" variant="contained"
                                onClick={() => props.onEditFlightPress()}
                                >Edit</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="zerogap">

                                {returnToggle && (
                                    <Box className="tabsBox">
                                        <Tabs className="flighttabsdetails" value={value} onChange={handleReturnFlighttabsChange} aria-label="simple tabs example">
                                            <Tab className="flighttabsdetails-link" label="flight details" {...viewReturnDetailsToggle(0)} />
                                            <Tab className="flighttabsdetails-link" label="fare details" {...viewReturnDetailsToggle(1)} />
                                            <Tab className="flighttabsdetails-link" label="Fare Rules" {...viewReturnDetailsToggle(2)} />
                                            <Tab className="flighttabsdetails-link" label="baggage information" {...viewReturnDetailsToggle(3)} />
                                        </Tabs>
                                        {/* <Box className="tltfaretype-desc">Fare Type:
                                            <span className="tltBaggage-sm"> &nbsp;{returnFlight?.Attr.Faretype}</span>
                                        </Box> */}
                                        <TabPanel className="tabpanelsflightpanel" value={value} index={0}>

                                            {returnFlight?.SegmentDetails[0].map((value, index) => (

                                                <Box className="tabcontentsbox">
                                                    <Box className="to-fromtlt">
                                                        <span className="fromname">{value.OriginDetails.CityName} </span>
                                                        <span className="icoarrowfromto">
                                                            <img src={arrowimg}></img>
                                                        </span>
                                                        <span className="toname">{value.DestinationDetails.CityName}</span>
                                                        <span className="dateformto">{formatDate(value.OriginDetails.DateTime)}</span>
                                                    </Box>
                                                    <Row>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                            <Box className="flightimg">
                                                                <Box className="imgminifly">
                                                                    <img className={'itwgo-60x35_' + value.AirlineDetails.AirlineCode} />
                                                                </Box>
                                                                <Box className="mini-tag">
                                                                    <Box className="flightname">{value.AirlineDetails.AirlineName}</Box>
                                                                    <Box className="flightnumber">{value.AirlineDetails.AirlineCode}-{value.AirlineDetails.FlightNumber}</Box>
                                                                </Box>
                                                            </Box>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Row>
                                                                <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                                                    <Box className="flightairportaddress">
                                                                        <div className="tltdate">{formatDate1(value.OriginDetails.DateTime)}</div>
                                                                        <p className='mb-0'>{value.OriginDetails.AirportName}, {value.OriginDetails.CityName}</p>
                                                                        {value?.OriginDetails?.Terminal ? <p>Terminal {value.OriginDetails.Terminal}</p> : null}
                                                                    </Box>
                                                                </Col>
                                                                <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap m-inherit">
                                                                    <Box className="arrowtabtxt">Non-Stop</Box>
                                                                    <Box className="arrowtabinfo"><img src={arrowimg}></img></Box>
                                                                    <Box className="define-timeinfo">{value.SegmentDuration}</Box>
                                                                </Col>
                                                                <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                                                    <Box className="flightairportaddress">
                                                                        <div className="tltdate">{formatDate1(value.DestinationDetails.DateTime)}</div>
                                                                        <p className='mb-0'>{value.DestinationDetails.AirportName}, {value.DestinationDetails.CityName}</p>
                                                                        {value?.DestinationDetails?.Terminal ? <p>Terminal {value.DestinationDetails.Terminal}</p> : null}

                                                                    </Box>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                            <Box className="time-calc">{value.SegmentDuration}</Box>
                                                            <Box className="type">{fsRoundReqData?.v_class}</Box>
                                                            {/* <Box className="seathighighted"><span className="seathightlighted">{value.AvailableSeats} seats left</span> </Box> */}
                                                        </Col>
                                                    </Row>
                                                    <Box className="layoverbox">
                                                        {
                                                            index < returnFlight?.SegmentDetails[0].length - 1
                                                                ? <Box className="layover">Layover <span>{value.WaitingTime}</span></Box>
                                                                : <Box></Box>
                                                        }
                                                    </Box>
                                                </Box>
                                            ))
                                            }

                                        </TabPanel>

                                        <TabPanel value={value} index={1}>
                                            <Row className="headerRow">
                                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <Box className="tltBaggage">Type</Box>
                                                </Col>
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                    <Box className="tltBaggage">Fare</Box>
                                                </Col>
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                    <Box className="tltBaggage">Total</Box>
                                                </Col>
                                            </Row>

                                            {!!fsRoundReqData?.adult && (
                                                <>
                                                    <Row>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                                                            <Box className="tltfaredetails"><span className="note-qt">Fare Details for Adult({returnFlight?.SegmentSummary[0].AirlineDetails.FareClass}) ({returnFlight?.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                                            <Box className="tltfaredetails">Base Price</Box>
                                                            <Box className="tltfaredetails">Taxes & fee</Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                            <Box className="tltfaredetails">&nbsp;</Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(returnFlight?.PassengerFareBreakdown.ADT.BaseFare) / (returnFlight?.PassengerFareBreakdown.ADT.PassengerCount)} X {returnFlight?.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>

                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((returnFlight?.PassengerFareBreakdown.ADT.Tax) / (returnFlight?.PassengerFareBreakdown.ADT.PassengerCount) + (returnFlight?.FareDetails.b2b_PriceDetails._Markup) / (paxCount))} X {returnFlight?.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                            <Box className="tltfaredetails">&nbsp;</Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(returnFlight?.PassengerFareBreakdown.ADT.BaseFare)}</span></Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((returnFlight?.PassengerFareBreakdown.ADT.Tax) / (returnFlight?.PassengerFareBreakdown.ADT.PassengerCount) + (returnFlight?.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (returnFlight?.PassengerFareBreakdown.ADT.PassengerCount)}</span></Box>
                                                        </Col>
                                                    </Row>
                                                    <Row className="totalRow">
                                                        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                                            <Box className="tlt-total">Total</Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                            <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(returnFlight?.PassengerFareBreakdown.ADT.BaseFare + ((returnFlight?.PassengerFareBreakdown.ADT.Tax) / (returnFlight?.PassengerFareBreakdown.ADT.PassengerCount) + (returnFlight?.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (returnFlight?.PassengerFareBreakdown.ADT.PassengerCount))}</span></Box>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}

                                            {!!fsRoundReqData?.child && (
                                                <>
                                                    <Row>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Box className="tltfaredetails"><span className="note-qt">Fare Details for Child({returnFlight?.SegmentSummary[0].AirlineDetails.FareClass}) ({returnFlight?.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                                            <Box className="tltfaredetails">Base Price</Box>
                                                            <Box className="tltfaredetails">Taxes & fee</Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                            <Box className="tltfaredetails">&nbsp;</Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(returnFlight?.PassengerFareBreakdown.CHD.BaseFare) / (returnFlight?.PassengerFareBreakdown.CHD.PassengerCount)} X {returnFlight?.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>

                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((returnFlight?.PassengerFareBreakdown.CHD.Tax) / (returnFlight?.PassengerFareBreakdown.CHD.PassengerCount) + (returnFlight?.FareDetails.b2b_PriceDetails._Markup) / (paxCount))} X {returnFlight?.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                            <Box className="tltfaredetails">&nbsp;</Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(returnFlight?.PassengerFareBreakdown.CHD.BaseFare)}</span></Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((returnFlight?.PassengerFareBreakdown.CHD.Tax) / (returnFlight?.PassengerFareBreakdown.CHD.PassengerCount) + (returnFlight?.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (returnFlight?.PassengerFareBreakdown.CHD.PassengerCount)}</span></Box>
                                                        </Col>
                                                    </Row>
                                                    <Row className="totalRow">
                                                        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                                            <Box className="tlt-total">Total</Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                            <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(returnFlight?.PassengerFareBreakdown.CHD.BaseFare + ((returnFlight?.PassengerFareBreakdown.CHD.Tax) / (returnFlight?.PassengerFareBreakdown.CHD.PassengerCount) + (returnFlight?.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (returnFlight?.PassengerFareBreakdown.CHD.PassengerCount))}</span></Box>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}

                                            {!!fsRoundReqData?.infant && (
                                                <>
                                                    <Row>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Box className="tltfaredetails"><span className="note-qt">Fare Details for Infant({returnFlight?.SegmentSummary[0].AirlineDetails.FareClass}) ({returnFlight?.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                                            <Box className="tltfaredetails">Base Price</Box>
                                                            <Box className="tltfaredetails">Taxes & fee</Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                            <Box className="tltfaredetails">&nbsp;</Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(returnFlight?.PassengerFareBreakdown.INF.BaseFare) / (returnFlight?.PassengerFareBreakdown.INF.PassengerCount)} X {returnFlight?.PassengerFareBreakdown.INF.PassengerCount}</span></Box>

                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((returnFlight?.PassengerFareBreakdown.INF.Tax) / (returnFlight?.PassengerFareBreakdown.INF.PassengerCount))} X {returnFlight?.PassengerFareBreakdown.INF.PassengerCount}</span></Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                            <Box className="tltfaredetails">&nbsp;</Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(returnFlight?.PassengerFareBreakdown.INF.BaseFare)}</span></Box>
                                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(Math.ceil(returnFlight?.PassengerFareBreakdown.INF.Tax))}</span></Box>
                                                        </Col>
                                                    </Row>
                                                    <Row className="totalRow">
                                                        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                                            <Box className="tlt-total">Total</Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                            <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(returnFlight?.PassengerFareBreakdown.INF.BaseFare + ((returnFlight?.PassengerFareBreakdown.INF.Tax) / (returnFlight?.PassengerFareBreakdown.INF.PassengerCount) + (returnFlight?.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (returnFlight?.PassengerFareBreakdown.INF.PassengerCount))}</span></Box>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}

                                        </TabPanel>

                                        <TabPanel value={value} index={2}>

                                            {fareLoader
                                                ? (
                                                    <span>Please wait...</span>
                                                )
                                                : (
                                                    <>
                                                        {/* <p className="p-font14">{ReactHtmlParser(fareRules)}</p> */}
                                                    </>
                                                )}

                                        </TabPanel>

                                        <TabPanel value={value} index={3}>
                                            <>
                                                <Row className="headerRow">
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                        <Box className="tltBaggage">Sector</Box>
                                                    </Col>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Box className="tltBaggage">Check-in</Box>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                        <Box className="tltBaggage">Cabin</Box>
                                                    </Col>
                                                </Row>

                                                {returnFlight?.SegmentDetails[0].map((value, index) => (
                                                    <Row>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                            <Box className="tltBaggage-desc">{value.OriginDetails.AirportCode} - {value.DestinationDetails.AirportCode}</Box>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Box className="tltBaggage-desc">{value.Baggage}</Box>
                                                        </Col>
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                            <Box className="tltBaggage-desc">{value.CabinBaggage}</Box>
                                                        </Col>
                                                    </Row>
                                                ))}

                                            </>
                                        </TabPanel>
                                        <Box className="closebox" onClick={viewReturnDetailToggle}><CloseIcon /></Box>
                                    </Box>

                                )}

                            </Col>
                        </Row>
                        <h6 className='ttl-pax'>Total Pax: {totalPaxCount}</h6>
                    </Box>
                </Box>
            ) : (onewayFlight === null && onwardFlight?.SegmentSummary?.length > 0) ? 
            (
                <Box className='acti-flight-list card card-body'>
                    <Box className="bgflightdetailscard">
                        <Box className='flight-heading'>
                            <h1>Selected Flight</h1>
                        </Box>
                        <Row className="viewresultbooking">
                            <Col xs={5} sm={5} md={8}>
                                <Row>
                                    <Col xs={5} sm={4} md={4} className="zerogutter m-auto">
                                        <Box className="flightimg">
                                            <Box className='imgminifly'>
                                            </Box>
                                            <Box className="departimgminifly">
                                                <img className={'itwgo-60x35_' + onwardFlight?.SegmentSummary[0]?.AirlineDetails?.AirlineCode} />
                                            </Box>
                                            <Box className="mini-tag">
                                                <Box className="flightname">{onwardFlight?.SegmentSummary[0]?.AirlineDetails?.AirlineName}</Box>
                                                <Box className="flightnumber">{onwardFlight?.SegmentSummary[0]?.AirlineDetails?.AirlineCode}-{onwardFlight?.SegmentSummary[0]?.AirlineDetails?.FlightNumber}</Box>
                                            </Box>
                                        </Box>

                                    </Col>
                                    <Col xs={7} sm={8} md={8} className="pl-xs-0">
                                        <Box className="flexset">
                                            <Box className="definewidth">
                                                <Box className="flightmininame">{onwardFlight?.SegmentSummary[0]?.OriginDetails?.CityName}</Box>
                                                <Box className="flightminitime">{moment(onwardFlight?.SegmentSummary[0]?.OriginDetails?.DateTime).format('hh:mm')}</Box>
                                                <Box className="flightminidata">{moment(onwardFlight?.SegmentSummary[0]?.OriginDetails?.DateTime).format('Do MMM YYYY')}</Box>
                                            </Box>
                                            <Box className="arrowbox ">
                                                <Box className="define-stop">{stopsCount[onwardFlight?.SegmentSummary[0]?.TotalStops]}</Box>
                                                <Box className="arrow-img"> <span className='arrow-line'></span><img src={arrowFlight}></img></Box>
                                                <Box className="define-timecalc">{onwardFlight?.SegmentSummary[0]?.TotalDuaration}</Box>
                                            </Box>
                                            <Box className="definewidth">
                                                <Box className="flightmininame">{onwardFlight?.SegmentSummary[0]?.DestinationDetails?.CityName}</Box>
                                                <Box className="flightminitime">{moment(onwardFlight?.SegmentSummary[0]?.DestinationDetails?.DateTime).format('hh:mm')}</Box>
                                                <Box className="flightminidata">{moment(onwardFlight?.SegmentSummary[0]?.DestinationDetails?.DateTime).format('Do MMM YYYY')}</Box>
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={5} sm={4} md={4} className="zerogutter m-auto">
                                        <Box className="flightimg">
                                            <Box className='imgminifly'>
                                            </Box>
                                            <Box className="departimgminifly">
                                                <img className={'itwgo-60x35_' + returnFlight?.SegmentSummary[0]?.AirlineDetails?.AirlineCode} />
                                            </Box>
                                            <Box className="mini-tag">
                                                <Box className="flightname">{returnFlight?.SegmentSummary[0]?.AirlineDetails?.AirlineName}</Box>
                                                <Box className="flightnumber">{returnFlight?.SegmentSummary[0]?.AirlineDetails?.AirlineCode}-{returnFlight?.SegmentSummary[0]?.AirlineDetails?.FlightNumber}</Box>
                                            </Box>
                                        </Box>

                                    </Col>
                                    <Col xs={7} sm={8} md={8} className="pl-xs-0">
                                        <Box className="flexset">
                                            <Box className="definewidth">
                                                <Box className="flightmininame">{returnFlight?.SegmentSummary[0]?.OriginDetails?.CityName}</Box>
                                                <Box className="flightminitime">{moment(returnFlight?.SegmentSummary[0]?.OriginDetails?.DateTime).format('hh:mm')}</Box>
                                                <Box className="flightminidata">{moment(returnFlight?.SegmentSummary[0]?.OriginDetails?.DateTime).format('Do MMM YYYY')}</Box>
                                            </Box>
                                            <Box className="arrowbox ">
                                                <Box className="define-stop">{stopsCount[returnFlight?.SegmentSummary[0]?.TotalStops]}</Box>
                                                <Box className="arrow-img"> <span className='arrow-line'></span><img src={arrowFlight}></img></Box>
                                                <Box className="define-timecalc">{returnFlight?.SegmentSummary[0]?.TotalDuaration}</Box>
                                            </Box>
                                            <Box className="definewidth">
                                                <Box className="flightmininame">{returnFlight?.SegmentSummary[0]?.DestinationDetails?.CityName}</Box>
                                                <Box className="flightminitime">{moment(returnFlight?.SegmentSummary[0]?.DestinationDetails?.DateTime).format('hh:mm')}</Box>
                                                <Box className="flightminidata">{moment(returnFlight?.SegmentSummary[0]?.DestinationDetails?.DateTime).format('Do MMM YYYY')}</Box>
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>
                            </Col>
                            
                            <Col xs={3} md={2} className="zerogap m-auto">
                                <Box className="radioBox">
                                    <Box row aria-label="position" name="position" defaultValue="top"
                                        orientation="vertical"
                                    >
                                        <Box className="servicesBox">
                                            <Row>
                                                <Col md={8} xs={8} className="servicesBoxs d-flex">
                                                    <Box className='pricewidth'>
                                                        <Box className="highlight-price"><span>	&#x20B9; </span> {onwardFlight?.FareDetails?.b2b_PriceDetails?._CustomerBuying}
                                                            &nbsp;<Tooltip onClick={() => viewDetailToggle(onwardFlight, "1")}
                                                                title="Fare Rules" arrow placement="top" ><InfoOutlinedIcon className='fare-rule-icon' /></Tooltip>
                                                        </Box>
                                                    </Box>
                                                </Col>
                                            </Row>
                                        </Box>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={3} md={2} className="m-auto">
                                <Button className="btnBook" variant="contained"
                                onClick={() => props.onEditFlightPress()}
                                >Edit</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="zerogap">

                            {toggle &&  (
                                <Box className="tabsBox">
                                    <Tabs className="flighttabsdetails" value={value} onChange={handleIntFlighttabsChange} aria-label="simple tabs example">
                                        <Tab className="flighttabsdetails-link" label="flight details" {...viewDetailsToggle(0)} />
                                        <Tab className="flighttabsdetails-link" label="fare details" {...viewDetailsToggle(1)} />
                                        <Tab className="flighttabsdetails-link" label="Fare Rules" {...viewDetailsToggle(2)} />
                                        <Tab className="flighttabsdetails-link" label="baggage info" {...viewDetailsToggle(3)} />
                                    </Tabs>
                                    <TabPanel className="tabpanelsflightpanel" value={value} index={0}>

                                        {[...onwardFlight?.SegmentDetails, ...returnFlight?.SegmentDetails].map((segSumry, index) => (
                                            <>
                                                {segSumry.map((v, k) => (
                                                    <>
                                                        <Box className="tabcontentsbox">
                                                            <Box className="to-fromtlt">
                                                                <span className="fromname">{v.OriginDetails.CityName}</span>
                                                                <span className="icoarrowfromto"><img src={arrowimg}></img></span>
                                                                <span className="toname">{v.DestinationDetails.CityName}</span>
                                                                <span className="dateformto">{formatDate(v.OriginDetails.DateTime)}</span>
                                                            </Box>
                                                            <Row>
                                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                                    <Box className="flightimg">
                                                                        <Box className="imgminifly">
                                                                            <img className={'itwgo-60x35_' + v.AirlineDetails.AirlineCode} />
                                                                        </Box>
                                                                        <Box className="mini-tag">
                                                                            <Box className="flightname">{v.AirlineDetails.AirlineName}</Box>
                                                                            <Box className="flightnumber">{v.AirlineDetails.AirlineCode}-{v.AirlineDetails.FlightNumber}</Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Col>
                                                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                    <Row>
                                                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                                                            <Box className="flightairportaddress">
                                                                                <div className="tltdate">{formatDate1(v.OriginDetails.DateTime)}</div>
                                                                                <p>{v.OriginDetails.AirportName + ',' + v.OriginDetails.CityName}</p>
                                                                            </Box>
                                                                        </Col>
                                                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                                                            <Box className="arrowtabtxt">Non-Stop</Box>
                                                                            <Box className="arrowtabinfo"><img src={arrowimg}></img></Box>
                                                                            <Box className="define-timeinfo">{v.SegmentDuration}</Box>
                                                                        </Col>
                                                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                                                            <Box className="flightairportaddress">
                                                                                <div className="tltdate">{formatDate1(v.DestinationDetails.DateTime)}</div>
                                                                                <p>{v.DestinationDetails.CityName + ', ' + v.DestinationDetails.AirportName}</p>
                                                                            </Box>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                                    <Box className="time-calc">{v.SegmentDuration}</Box>
                                                                    {/* <Box className="type">{fsRequestData?.v_class}</Box> */}
                                                                    {/* <Box className="seathighighted"><span className="seathightlighted">{v.AvailableSeats} seats left</span> </Box> */}
                                                                </Col>
                                                            </Row>
                                                            <Box className="layoverbox">
                                                                {
                                                                    k < segSumry.length - 1
                                                                      ? <Box className="layover">Layover <span>{v.WaitingTime}</span></Box>
                                                                      : <Box></Box>
                                                                }
                                                            </Box>
                                                        </Box>

                                                    </>
                                                ))}

                                            </>
                                        ))}

                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <Row className="headerRow">
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <Box className="tltBaggage">Type</Box>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <Box className="tltBaggage">Fare</Box>
                                            </Col>
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <Box className="tltBaggage">Total</Box>
                                            </Col>
                                        </Row>

                                        {!!fsRoundReqData?.adult && (
                                            <>
                                                <Row>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tltfaredetails"><span className="note-qt">Fare Details for Adult({onwardFlight.SegmentSummary[0].AirlineDetails.FareClass}) ({onwardFlight.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                            <Box className="tltfaredetails">Base Price</Box>
                                            <Box className="tltfaredetails">Taxes & fee</Box>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <Box className="tltfaredetails">&nbsp;</Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(onwardFlight.PassengerFareBreakdown.ADT.BaseFare) / (onwardFlight.PassengerFareBreakdown.ADT.PassengerCount) } X {onwardFlight.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>

                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((onwardFlight.PassengerFareBreakdown.ADT.Tax) / (onwardFlight.PassengerFareBreakdown.ADT.PassengerCount) + (onwardFlight.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) } X {onwardFlight.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>
                                        </Col>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tltfaredetails">&nbsp;</Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(onwardFlight.PassengerFareBreakdown.ADT.BaseFare)}</span></Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((onwardFlight.PassengerFareBreakdown.ADT.Tax) / (onwardFlight.PassengerFareBreakdown.ADT.PassengerCount) + (onwardFlight.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (onwardFlight.PassengerFareBreakdown.ADT.PassengerCount) }</span></Box>
                                        </Col>
                                    </Row>
                                    <Row className="totalRow">
                                        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                            <Box className="tlt-total">Total</Box>
                                        </Col>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(onwardFlight.PassengerFareBreakdown.ADT.BaseFare + ((onwardFlight.PassengerFareBreakdown.ADT.Tax) / (onwardFlight.PassengerFareBreakdown.ADT.PassengerCount) + (onwardFlight.FareDetails.b2b_PriceDetails._Markup) / (paxCount))) * (onwardFlight.PassengerFareBreakdown.ADT.PassengerCount) }</span></Box>
                                        </Col>
                                    </Row>
                                            </>
                                        )}

                                        {!!fsRoundReqData?.child && (
                                            <>
                                                <Row>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tltfaredetails"><span className="note-qt">Fare Details for Child({onwardFlight.SegmentSummary[0].AirlineDetails.FareClass}) ({onwardFlight.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                            <Box className="tltfaredetails">Base Price</Box>
                                            <Box className="tltfaredetails">Taxes & fee</Box>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <Box className="tltfaredetails">&nbsp;</Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(onwardFlight.PassengerFareBreakdown.CHD.BaseFare) / (onwardFlight.PassengerFareBreakdown.CHD.PassengerCount) } X {onwardFlight.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>

                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((onwardFlight.PassengerFareBreakdown.CHD.Tax) / (onwardFlight.PassengerFareBreakdown.CHD.PassengerCount) + (onwardFlight.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) } X {onwardFlight.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>
                                        </Col>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tltfaredetails">&nbsp;</Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(onwardFlight.PassengerFareBreakdown.CHD.BaseFare)}</span></Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((onwardFlight.PassengerFareBreakdown.CHD.Tax) / (onwardFlight.PassengerFareBreakdown.CHD.PassengerCount) + (onwardFlight.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (onwardFlight.PassengerFareBreakdown.CHD.PassengerCount) }</span></Box>
                                        </Col>
                                    </Row>
                                    <Row className="totalRow">
                                        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                            <Box className="tlt-total">Total</Box>
                                        </Col>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(onwardFlight.PassengerFareBreakdown.CHD.BaseFare + ((onwardFlight.PassengerFareBreakdown.CHD.Tax) / (onwardFlight.PassengerFareBreakdown.CHD.PassengerCount) + (onwardFlight.FareDetails.b2b_PriceDetails._Markup) / (paxCount))) * (onwardFlight.PassengerFareBreakdown.CHD.PassengerCount) }</span></Box>
                                        </Col>
                                    </Row>
                                            </>
                                        )}

                                        {!!fsRoundReqData?.infant && (
                                            <>
                                                <Row>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tltfaredetails"><span className="note-qt">Fare Details for Infant({onwardFlight.SegmentSummary[0].AirlineDetails.FareClass}) ({onwardFlight.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                            <Box className="tltfaredetails">Base Price</Box>
                                            <Box className="tltfaredetails">Taxes & fee</Box>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <Box className="tltfaredetails">&nbsp;</Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(onwardFlight.PassengerFareBreakdown.INF.BaseFare) / (onwardFlight.PassengerFareBreakdown.INF.PassengerCount) } X {onwardFlight.PassengerFareBreakdown.INF.PassengerCount}</span></Box>

                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((onwardFlight.PassengerFareBreakdown.INF.Tax) / (onwardFlight.PassengerFareBreakdown.INF.PassengerCount)) } X {onwardFlight.PassengerFareBreakdown.INF.PassengerCount}</span></Box>
                                        </Col>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tltfaredetails">&nbsp;</Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(onwardFlight.PassengerFareBreakdown.INF.BaseFare)}</span></Box>
                                            <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(Math.ceil(onwardFlight.PassengerFareBreakdown.INF.Tax))}</span></Box>
                                        </Col>
                                    </Row>
                                    <Row className="totalRow">
                                        <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                            <Box className="tlt-total">Total</Box>
                                        </Col>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(onwardFlight.PassengerFareBreakdown.INF.BaseFare + onwardFlight.PassengerFareBreakdown.INF.Tax)}</span></Box>
                                        </Col>
                                    </Row>
                                            </>
                                        )}

                                    </TabPanel>

                                    <TabPanel value={value} index={2}>
                                        {fareLoader
                                          ? (
                                            <span>Please wait...</span>
                                            )
                                          : (
                                            <>
                                                 {/* <p className="p-font14">{ReactHtmlParser(fareRules)}</p> */}
                                            </>
                                            )}

                                    </TabPanel>

                                    <TabPanel value={value} index={3}>
                                        <Row className="headerRow">
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <Box className="tltBaggage">Sector</Box>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <Box className="tltBaggage">Check-in</Box>
                                            </Col>
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <Box className="tltBaggage">Cabin</Box>
                                            </Col>
                                        </Row>

                                        {[...onwardFlight?.SegmentDetails, ...returnFlight?.SegmentDetails].map((segSumry, index) => (
                                            <>
                                                {segSumry.map((v, k) => (
                                                    <>
                                                        <Row>
                                                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                                <Box className="tltBaggage-desc">{v.OriginDetails.AirportCode} - {v.DestinationDetails.AirportCode}</Box>
                                                            </Col>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                <Box className="tltBaggage-desc">{v.Baggage}</Box>
                                                            </Col>
                                                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                                <Box className="tltBaggage-desc">{v.CabinBaggage ? v.CabinBaggage : '-----'}</Box>
                                                            </Col>
                                                        </Row>

                                                    </>
                                                ))}
                                            </>
                                        ))}

                                    </TabPanel>
                                    <Box className="closebox" onClick={viewDetailToggle}><CloseIcon /></Box>
                                </Box>

                            )}

                            </Col>
                        </Row>
                        <h6 className='ttl-pax'>Total Pax: {totalPaxCount}</h6>
                    </Box>
                </Box>
            ) : null}
            {(onewayFlight !== null && onewayFlight?.SegmentSummary?.length > 0) && (
            <Box className='acti-flight-list card card-body'>
                <Box className="bgflightdetailscard">
                    <Box className='flight-heading'>
                        <h1>Selected Flight</h1>
                    </Box>
                    <Row className="viewresultbooking">
                        <Col xs={5} sm={4} md={3} className="zerogutter m-auto">
                            <Box className="flightimg">
                                <Box className='imgminifly'>
                                </Box>
                                <Box className="departimgminifly">
                                    <img className={'itwgo-60x35_' + onewayFlight?.SegmentSummary[0]?.AirlineDetails?.AirlineCode} />
                                </Box>
                                <Box className="mini-tag">
                                    <Box className="flightname">{onewayFlight?.SegmentSummary[0]?.AirlineDetails?.AirlineName}</Box>
                                    <Box className="flightnumber">{onewayFlight?.SegmentSummary[0]?.AirlineDetails?.AirlineCode}-{onewayFlight?.SegmentSummary[0]?.AirlineDetails?.FlightNumber}</Box>
                                </Box>
                            </Box>

                        </Col>
                        <Col xs={7} sm={8} md={5} className="pl-xs-0">
                            <Box className="flexset">
                                <Box className="definewidth">
                                    <Box className="flightmininame">{onewayFlight?.SegmentSummary[0]?.OriginDetails?.CityName}</Box>
                                    <Box className="flightminitime">{moment(onewayFlight?.SegmentSummary[0]?.OriginDetails?.DateTime).format('hh:mm')}</Box>
                                    <Box className="flightminidata">{moment(onewayFlight?.SegmentSummary[0]?.OriginDetails?.DateTime).format('Do MMM YYYY')}</Box>
                                </Box>
                                <Box className="arrowbox ">
                                    <Box className="define-stop">{stopsCount[onewayFlight?.SegmentSummary[0]?.TotalStops]}</Box>
                                    <Box className="arrow-img"> <span className='arrow-line'></span><img src={arrowFlight}></img></Box>
                                    <Box className="define-timecalc">{onewayFlight?.SegmentSummary[0]?.TotalDuaration}</Box>
                                </Box>
                                <Box className="definewidth">
                                    <Box className="flightmininame">{onewayFlight?.SegmentSummary[0]?.DestinationDetails?.CityName}</Box>
                                    <Box className="flightminitime">{moment(onewayFlight?.SegmentSummary[0]?.DestinationDetails?.DateTime).format('hh:mm')}</Box>
                                    <Box className="flightminidata">{moment(onewayFlight?.SegmentSummary[0]?.DestinationDetails?.DateTime).format('Do MMM YYYY')}</Box>
                                </Box>
                            </Box>
                        </Col>
                        <Col xs={9} md={2} className="zerogap m-auto">
                            <Box className="radioBox">
                                <Box row aria-label="position" name="position" defaultValue="top"
                                    orientation="vertical"
                                // value={fareValues}
                                // onChange={handleChangeFares}
                                >
                                    <Box className="servicesBox">
                                        <Row>
                                            <Col md={8} xs={8} className="servicesBoxs d-flex">
                                                <Box className='pricewidth'>
                                                    <Box className="highlight-price"><span>	&#x20B9; </span> {onewayFlight?.FareDetails?.b2b_PriceDetails?._CustomerBuying}
                                                        &nbsp;<Tooltip onClick={() => viewOnewayDetailToggle(onewayFlight, "1")}
                                                            title="Fare Rules" arrow placement="top" ><InfoOutlinedIcon className='fare-rule-icon' /></Tooltip>
                                                    </Box>
                                                    {/* <Box className="itemssecttag">{onewayFlight?.Attr?.Faretype}</Box> */}
                                                </Box>
                                            </Col>
                                        </Row>
                                    </Box>
                                </Box>
                            </Box>
                        </Col>
                        <Col xs={3} md={2} className="m-auto">
                            <Button className="btnBook" variant="contained"
                            onClick={() => props.onEditFlightPress()}
                            >Edit</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="zerogap">

                            {onewayToggle && (
                                <Box className="tabsBox">
                                    <Tabs className="flighttabsdetails" value={value} onChange={handleOnewayFlighttabsChange} aria-label="simple tabs example">
                                        <Tab className="flighttabsdetails-link" label="flight details" {...viewOnewayDetailsToggle(0)} />
                                        <Tab className="flighttabsdetails-link" label="fare details" {...viewOnewayDetailsToggle(1)} />
                                        <Tab className="flighttabsdetails-link" label="Fare Rules" {...viewOnewayDetailsToggle(2)} />
                                        <Tab className="flighttabsdetails-link" label="baggage information" {...viewOnewayDetailsToggle(3)} />
                                    </Tabs>
                                    {/* <Box className="tltfaretype-desc">Fare Type:
                                        <span className="tltBaggage-sm"> &nbsp;{onewayFlight?.Attr.Faretype}</span>
                                    </Box> */}
                                    <TabPanel className="tabpanelsflightpanel" value={value} index={0}>

                                        {onewayFlight?.SegmentDetails[0].map((value, index) => (

                                            <Box className="tabcontentsbox">
                                                <Box className="to-fromtlt">
                                                    <span className="fromname">{value.OriginDetails.CityName} </span>
                                                    <span className="icoarrowfromto">
                                                        <img src={arrowimg}></img>
                                                    </span>
                                                    <span className="toname">{value.DestinationDetails.CityName}</span>
                                                    <span className="dateformto">{formatDate(value.OriginDetails.DateTime)}</span>
                                                </Box>
                                                <Row>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                        <Box className="flightimg">
                                                            <Box className="imgminifly">
                                                                <img className={'itwgo-60x35_' + value.AirlineDetails.AirlineCode} />
                                                            </Box>
                                                            <Box className="mini-tag">
                                                                <Box className="flightname">{value.AirlineDetails.AirlineName}</Box>
                                                                <Box className="flightnumber">{value.AirlineDetails.AirlineCode}-{value.AirlineDetails.FlightNumber}</Box>
                                                            </Box>
                                                        </Box>
                                                    </Col>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Row>
                                                            <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                                                <Box className="flightairportaddress">
                                                                    <div className="tltdate">{formatDate1(value.OriginDetails.DateTime)}</div>
                                                                    <p className='mb-0'>{value.OriginDetails.AirportName}, {value.OriginDetails.CityName}</p>
                                                                    {value?.OriginDetails?.Terminal ? <p>Terminal {value.OriginDetails.Terminal}</p> : null}
                                                                </Box>
                                                            </Col>
                                                            <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap m-inherit">
                                                                <Box className="arrowtabtxt">Non-Stop</Box>
                                                                <Box className="arrowtabinfo"><img src={arrowimg}></img></Box>
                                                                <Box className="define-timeinfo">{value.SegmentDuration}</Box>
                                                            </Col>
                                                            <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                                                <Box className="flightairportaddress">
                                                                    <div className="tltdate">{formatDate1(value.DestinationDetails.DateTime)}</div>
                                                                    <p className='mb-0'>{value.DestinationDetails.AirportName}, {value.DestinationDetails.CityName}</p>
                                                                    {value?.DestinationDetails?.Terminal ? <p>Terminal {value.DestinationDetails.Terminal}</p> : null}

                                                                </Box>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                        <Box className="time-calc">{value.SegmentDuration}</Box>
                                                        <Box className="type">{fsRoundReqData?.v_class}</Box>
                                                        {/* <Box className="seathighighted"><span className="seathightlighted">{value.AvailableSeats} seats left</span> </Box> */}
                                                    </Col>
                                                </Row>
                                                <Box className="layoverbox">
                                                    {
                                                        index < onewayFlight?.SegmentDetails[0].length - 1
                                                            ? <Box className="layover">Layover <span>{value.WaitingTime}</span></Box>
                                                            : <Box></Box>
                                                    }
                                                </Box>
                                            </Box>
                                        ))
                                        }

                                    </TabPanel>

                                    <TabPanel value={value} index={1}>
                                        <Row className="headerRow">
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <Box className="tltBaggage">Type</Box>
                                            </Col>
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <Box className="tltBaggage">Fare</Box>
                                            </Col>
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                <Box className="tltBaggage">Total</Box>
                                            </Col>
                                        </Row>

                                        {!!fsRequestData?.adult && (
                                            <>
                                                <Row>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                                                        <Box className="tltfaredetails"><span className="note-qt">Fare Details for Adult({onewayFlight?.SegmentSummary[0].AirlineDetails.FareClass}) ({onewayFlight?.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                                        <Box className="tltfaredetails">Base Price</Box>
                                                        <Box className="tltfaredetails">Taxes & fee</Box>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                        <Box className="tltfaredetails">&nbsp;</Box>
                                                        <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(onewayFlight?.PassengerFareBreakdown.ADT.BaseFare) / (onewayFlight?.PassengerFareBreakdown.ADT.PassengerCount)} X {onewayFlight?.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>

                                                        <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((onewayFlight?.PassengerFareBreakdown.ADT.Tax) / (onewayFlight?.PassengerFareBreakdown.ADT.PassengerCount) + (onewayFlight?.FareDetails.b2b_PriceDetails._Markup) / (onewayPaxCount))} X {onewayFlight?.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                        <Box className="tltfaredetails">&nbsp;</Box>
                                                        <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(onewayFlight?.PassengerFareBreakdown.ADT.BaseFare)}</span></Box>
                                                        <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((onewayFlight?.PassengerFareBreakdown.ADT.Tax) / (onewayFlight?.PassengerFareBreakdown.ADT.PassengerCount) + (onewayFlight?.FareDetails.b2b_PriceDetails._Markup) / (onewayPaxCount)) * (onewayFlight?.PassengerFareBreakdown.ADT.PassengerCount)}</span></Box>
                                                    </Col>
                                                </Row>
                                                <Row className="totalRow">
                                                    <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                                        <Box className="tlt-total">Total</Box>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                        <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(onewayFlight?.PassengerFareBreakdown.ADT.BaseFare + ((onewayFlight?.PassengerFareBreakdown.ADT.Tax) / (onewayFlight?.PassengerFareBreakdown.ADT.PassengerCount) + (onewayFlight?.FareDetails.b2b_PriceDetails._Markup) / (onewayPaxCount)) * (onewayFlight?.PassengerFareBreakdown.ADT.PassengerCount))}</span></Box>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}

                                        {!!fsRequestData?.child && (
                                            <>
                                                <Row>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Box className="tltfaredetails"><span className="note-qt">Fare Details for Child({onewayFlight?.SegmentSummary[0].AirlineDetails.FareClass}) ({onewayFlight?.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                                        <Box className="tltfaredetails">Base Price</Box>
                                                        <Box className="tltfaredetails">Taxes & fee</Box>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                        <Box className="tltfaredetails">&nbsp;</Box>
                                                        <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(onewayFlight?.PassengerFareBreakdown.CHD.BaseFare) / (onewayFlight?.PassengerFareBreakdown.CHD.PassengerCount)} X {onewayFlight?.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>

                                                        <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((onewayFlight?.PassengerFareBreakdown.CHD.Tax) / (onewayFlight?.PassengerFareBreakdown.CHD.PassengerCount) + (onewayFlight?.FareDetails.b2b_PriceDetails._Markup) / (onewayPaxCount))} X {onewayFlight?.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                        <Box className="tltfaredetails">&nbsp;</Box>
                                                        <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(onewayFlight?.PassengerFareBreakdown.CHD.BaseFare)}</span></Box>
                                                        <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((onewayFlight?.PassengerFareBreakdown.CHD.Tax) / (onewayFlight?.PassengerFareBreakdown.CHD.PassengerCount) + (onewayFlight?.FareDetails.b2b_PriceDetails._Markup) / (onewayPaxCount)) * (onewayFlight?.PassengerFareBreakdown.CHD.PassengerCount)}</span></Box>
                                                    </Col>
                                                </Row>
                                                <Row className="totalRow">
                                                    <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                                        <Box className="tlt-total">Total</Box>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                        <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(onewayFlight?.PassengerFareBreakdown.CHD.BaseFare + ((onewayFlight?.PassengerFareBreakdown.CHD.Tax) / (onewayFlight?.PassengerFareBreakdown.CHD.PassengerCount) + (onewayFlight?.FareDetails.b2b_PriceDetails._Markup) / (onewayPaxCount)) * (onewayFlight?.PassengerFareBreakdown.CHD.PassengerCount))}</span></Box>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}

                                        {!!fsRequestData?.infant && (
                                            <>
                                                <Row>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Box className="tltfaredetails"><span className="note-qt">Fare Details for Infant({onewayFlight?.SegmentSummary[0].AirlineDetails.FareClass}) ({onewayFlight?.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                                        <Box className="tltfaredetails">Base Price</Box>
                                                        <Box className="tltfaredetails">Taxes & fee</Box>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                        <Box className="tltfaredetails">&nbsp;</Box>
                                                        <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(onewayFlight?.PassengerFareBreakdown.INF.BaseFare) / (onewayFlight?.PassengerFareBreakdown.INF.PassengerCount)} X {onewayFlight?.PassengerFareBreakdown.INF.PassengerCount}</span></Box>

                                                        <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((onewayFlight?.PassengerFareBreakdown.INF.Tax) / (onewayFlight?.PassengerFareBreakdown.INF.PassengerCount))} X {onewayFlight?.PassengerFareBreakdown.INF.PassengerCount}</span></Box>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                        <Box className="tltfaredetails">&nbsp;</Box>
                                                        <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{toDigit(onewayFlight?.PassengerFareBreakdown.INF.BaseFare)}</span></Box>
                                                        <Box className="tltfaredetails"><span>&#x20B9; </span> <span>{(Math.ceil(onewayFlight?.PassengerFareBreakdown.INF.Tax))}</span></Box>
                                                    </Col>
                                                </Row>
                                                <Row className="totalRow">
                                                    <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                                        <Box className="tlt-total">Total</Box>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} className="text-right">
                                                        <Box className="tlt-total"><span>&#x20B9; </span> <span>{Math.ceil(onewayFlight?.PassengerFareBreakdown.INF.BaseFare + ((onewayFlight?.PassengerFareBreakdown.INF.Tax) / (onewayFlight?.PassengerFareBreakdown.INF.PassengerCount) + (onewayFlight?.FareDetails.b2b_PriceDetails._Markup) / (onewayPaxCount)) * (onewayFlight?.PassengerFareBreakdown.INF.PassengerCount))}</span></Box>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}

                                    </TabPanel>

                                    <TabPanel value={value} index={2}>

                                        {fareLoader
                                            ? (
                                                <span>Please wait...</span>
                                            )
                                            : (
                                                <>
                                                    {/* <p className="p-font14">{ReactHtmlParser(fareRules)}</p> */}
                                                </>
                                            )}

                                    </TabPanel>

                                    <TabPanel value={value} index={3}>
                                        <>
                                            <Row className="headerRow">
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                    <Box className="tltBaggage">Sector</Box>
                                                </Col>
                                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <Box className="tltBaggage">Check-in</Box>
                                                </Col>
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                    <Box className="tltBaggage">Cabin</Box>
                                                </Col>
                                            </Row>

                                            {onewayFlight?.SegmentDetails[0].map((value, index) => (
                                                <Row>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                        <Box className="tltBaggage-desc">{value.OriginDetails.AirportCode} - {value.DestinationDetails.AirportCode}</Box>
                                                    </Col>
                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Box className="tltBaggage-desc">{value.Baggage}</Box>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                        <Box className="tltBaggage-desc">{value.CabinBaggage}</Box>
                                                    </Col>
                                                </Row>
                                            ))}

                                        </>
                                    </TabPanel>



                                    <Box className="closebox" onClick={viewDetailToggle}><CloseIcon /></Box>
                                </Box>

                            )}

                        </Col>
                    </Row>
                    <h6 className='ttl-pax'>Total Pax: {totalPaxCount}</h6>
                </Box>
            </Box>
            )}
            
        </>
    );
}

export default FlightOverview