import { Box, Button, FormControlLabel, Radio, RadioGroup, Tab, Tabs, Typography, Tooltip, Checkbox , TextField} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import arrowimg from '../../../assets/images/arrow.svg'
import luggage from '../../../assets/images/luggage.svg'
import refund from '../../../assets/images/refund.svg'
import nonrefund from '../../../assets/images/nonrefund.svg'
import seat from '../../../assets/images/seat.svg'
import CloseIcon from '@mui/icons-material/Close'
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import '../../../assets/airlines/airlineLogoSprites.css'
import PropTypes from 'prop-types'
import { flightDate, formatDate, formatDate1, toDigit } from '../../../core/helper'
import { stopsCount } from '../../../core/airlineStops'
import { useSelector } from 'react-redux'
// import ReactHtmlParser from 'react-html-parser'
import { API_BASE_URL } from '../../../utility/apiConstants'
import { authPost } from '../../../utility/apiConfig'
import { fareRulsDetails } from '../../../services/fareDetails'
import './roundTripResults.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Regular = withStyles({
  root: {
    color: '#fc9e15',
    '&$checked': {
      color: '#fc9e15'
    }
  }
})(props => <Radio color="default" {...props} />)

const Tactical = withStyles({
  root: {
    color: '#e91e63',
    '&$checked': {
      color: '#e91e63'
    }
  }
})(props => <Radio color="default" {...props} />)

const SME = withStyles({
  root: {
    color: '#3A62D9',
    '&$checked': {
      color: '#3A62D9'
    }
  }
})(props => <Radio color="default" {...props} />)

const Deal = withStyles({
  root: {
    color: '#ff0000',
    '&$checked': {
      color: '#ff0000'
    }
  }
})(props => <Radio color="default" {...props} />)

const Corporate = withStyles({
  root: {
    color: '#28a745',
    '&$checked': {
      color: '#28a745'
    }
  }
})(props => <Radio color="default" {...props} />)

const Others = withStyles({
  root: {
    color: '#17a2b8',
    '&$checked': {
      color: '#17a2b8'
    }
  }
})(props => <Radio color="default" {...props} />)

function FareidentifierRadio(fareType) {
  switch (fareType) {
    case 'Regular':
      return (<Regular />)
      break

    case 'Tactical':
      return (<Tactical />)
      break

    case 'SME':
      return (<SME />)
      break

    case 'Deal':
      return (<Deal />)
      break

    case 'Refundable':
      return (<Corporate />)
      break

    default:
      return (<Others />)
      break
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function viewDetailsToggle(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}))

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {

  }
}))(Tooltip)

const RoundTripResults = (props) => {
  const result = props.results
  const { onwardSelected, returnSelected, type } = props

  const { fsRoundReqData } = useSelector(state => state.flightResult)
  const [fareDetails, setFareDetails] = useState({})
  const [fareValues, setFareValues] = useState('0')
  const [fareRules, setFareRules] = useState(null)
  const [fareLoader, setFareLoader] = useState(false)

  const [paxCount, setpaxCount] = useState(fsRoundReqData.adult + fsRoundReqData.child)

  const Roundclass = useStyles()
  const [value, setValue] = useState(0)
  const [toggle, setToggle] = useState(false)

  const segSumry = props.results[0].SegmentSummary[0]
  const segments = props.results[0].SegmentDetails[0]
  const airlines = segments[0].AirlineDetails

  const handleChangeFares = (event) => {
    setFareValues(event.target.value)
    setFareDetails(props.results[event.target.value])
    if(toggle && value === 2)
    {
      getFareRules(props.results[event.target.value])
    }
  }

  const getFareRules = useCallback(
    (data) => {
      setFareLoader(true)
      const obj = {
        data_access_key: data.Token,
        booking_source: data.booking_source,
        search_access_key: data.ProvabAuthKey,
        provab_auth_key: 'undefined'
      }

      try {
        authPost(API_BASE_URL + 'ajax/get_fare_details', obj)
          .then((response) => {
            setFareLoader(false)
            if (response.data.status) {
              const info = response.data.data[0]?.fare_rules
              if (info !== undefined) {
                const iic = info.concat(response.data.data[0]?.cancellation_policy)
                setFareRules(iic)
              } else {
                setFareRules(response.data.data.data[0].FareRules)
              }
            } else {
              setFareRules(response.data.data.msg)
            }
          }).catch((error) => {
            setFareRules("Fare details not available at this moment.")
          })
      } catch (error) {
        setFareLoader(false)
        setFareRules('Somthing went wrong please try again...')
      }
    }
  )

  const handleFlighttabsChange = (event, newValue) => {
    setValue(newValue)

    if (newValue === 2) {
      getFareRules(props.results[0])
    }
  }

  const viewDetailToggle = () => {
    setToggle(!toggle)
    setFareDetails(props.results[fareValues])
    setValue(0)
  }

  const selectFlight = (data, selct) => {

    if (props.type === 'onward') {
      props.onwardSelected(props.results[fareValues], selct)
    } else {
      props.returnSelected(props.results[fareValues], selct)
    }

    
  }

  const getSectionSelected = () => {
      let obj = props.results.filter((item)=> {return item.Token===props.onActiveBg})
       if (obj && obj.length > 0)
           return true
       else
           return false
  }

  useEffect(() => {
    setToggle(false)
    setFareValues('0')
  }, [props.results])
  return (
    <>
          <Box className={[getSectionSelected() ? 'roundflightdetailscard-xs activerndbg' : 'roundflightdetailscard-xs', "roundtripresult-mob", "roundtripdetails-mini"]}>
           
            <Row className="">
              <Col xs={12}  className="zerogap">
                <Box className="roundflightimg">
                  <Box className="roundimgminifly">
                    <div className={'flagflt itwgo-60x35_' + airlines.AirlineCode}></div>
                  </Box>
                  <Box className="roundmini-tag">
                    <Box className="rndflightname">{segSumry.AirlineDetails.AirlineName}</Box>
                    <Box className="rndflightnumber">{segSumry.AirlineDetails.AirlineCode + ' ' + segSumry.AirlineDetails.FlightNumber}</Box>
                  </Box>
                </Box>
                <Box>
                <Box className="roundmini-tag">
                    <Box className="flightminitime flighttimexs">{segSumry.OriginDetails._DateTime}</Box>
                    <Box className="flightminitime flighttimexs">{segSumry.DestinationDetails._DateTime}</Box>
                </Box>
                <Box className="round-radio-fieldwith-iconlist">
                  <Box className="roundflex-servicesbox">
                    <Box className="round-highlight-price"><span> &#x20B9; </span>{Math.ceil(props.results[0].PassengerFareBreakdown.ADT.BaseFare + ((props.results[0].PassengerFareBreakdown.ADT.Tax) / (props.results[0].PassengerFareBreakdown.ADT.PassengerCount) + (props.results[0].FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (props.results[0].PassengerFareBreakdown.ADT.PassengerCount))}</Box>
                  </Box>
                </Box>
              </Box>
            </Col>
          </Row>
        </Box>
      
    </>
  )
}

export default RoundTripResults

